import React from "react";
import { useNavigate } from "react-router-dom";
import "../Css/Callexist.css";

const Callexist = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    // Use the navigate function to redirect to the specified URL
    navigate("/");
  };

  
  const handleButtonClick = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.rxone&hl=en-IN",
      "_blank"
    );
  };

  const handleAppleClick = () => {
    window.open(
      "https://apps.apple.com/in/app/rxone-care-to-cure/id6479252685",
      "_blank"
    );
  };

  return (
  <div role="article" aria-roledescription="email" lang="en" className="email-container">
    <table role="presentation" className="email-table">
      <tr>
        <td align="center" className="email-td">
          <table role="presentation" className="inner-table">
            <tr>
              <td className="logo-td">
                <a href="https://rxone.app" className="logo-link">
                  <img src="https://rxone-static.s3.ap-south-1.amazonaws.com/images/rxone_white.png" alt="RxOne Logo" className="logo-img" />
                </a>
              </td>
            </tr>
            <tr>
              <td className="content-td">
                <h1>Your call is successfully completed!</h1>
                <a href="https://rxone.app" className="content-link">
                  <img src="https://rxone-static.s3.ap-south-1.amazonaws.com/images/completed.gif" alt="" className="content-img" />
                </a>
                <div style={{textAlign: "center"}}>
                  <p style={{textAlign: "center", fontSize: "17px", fontWeight: "600"}}>Manage your health records, prescriptions, body vitals. Consult with top specialists. Discover treatments and more. Download Now!</p>
                    <button
                      type="button"
                      className="getapp-container"
                      onClick={handleButtonClick}
                    >
                      <img
                        src={require("../Images/googleplaystore-icon.png")}
                        alt="Get the app on Google Play"
                      />
                    </button>
                    <button
                      type="button"
                      className="getapp-contain"
                      onClick={handleAppleClick}
                      style={{ margin: "8px" }}
                    >
                      <img
                        src={require("../Images/Appleicon.png")}
                        alt="Get the app on Apple Play"
                      />
                    </button>
                  </div>
                <p>Click to close the window:</p>
                <div className="button-container">
                  <button id="close" onClick={() => window.close()}  className="close-button">CLOSE</button>
                </div>
              
              </td>
            </tr>
            <tr>
              <td className="footer-td">
                <p>
                  <a href="https://www.facebook.com/rxonecare">
                    <img src="https://rxone-static.s3.ap-south-1.amazonaws.com/images/facebook2x.png" alt="Facebook" className="social-icon" />
                  </a>
                  <a href="https://twitter.com/RxOneCare">
                    <img src="https://rxone-static.s3.ap-south-1.amazonaws.com/images/twitter2x.png" alt="Twitter" className="social-icon" />
                  </a>
                  <a href="https://www.linkedin.com/company/rx-one">
                    <img src="https://rxone-static.s3.ap-south-1.amazonaws.com/images/linkedin2x.png" alt="LinkedIn" className="social-icon" />
                  </a>
                </p>
                <p>RxOne © Rx One Care 2024<br /><a href="mailto: support@RxOne.app" className="unsub-link">support@RxOne.app</a></p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
);
};


export default Callexist;
