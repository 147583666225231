import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ServiceCall from "../ServiceCall";
import nogif from "../../src/Images/no-appointment.gif"

const Prescription = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { code } = useParams(); // Get the code from the route parameters

  useEffect(() => {
    const fetchPrescription = async () => {
      if (!code) return; // If no code, return early

      // Store code in localStorage and sessionStorage
      localStorage.setItem("code", code);
      sessionStorage.setItem("code", code);

      // Start loading
      setLoading(true);
      setError(null);

      try {
        // Make the API call
        console.log("Fetching prescription for code:", code); // Debug log
        const response = await ServiceCall.get(
          `/rxopd/fetch/prescription/${code}`
        );

        // Check if the response contains the "url not found" message
        if (response.data.message === "Rxone Prescription url not found") {
            const msg = response.data.message;
          setError(msg); // Set a custom error state for this specific message
        } else {
            setLoading(false);
            setData(response.data);
          window.open(response.data, ); // Open the link in a new tab
        }
      } catch (error) {
        console.error("API call error:", error); // Debug log
        setError(
          error.response?.data?.message ||
            error.message ||
            "Failed to fetch prescription"
        );
      } finally {
        setLoading(false);
      }
    };

    // Call the function immediately
    fetchPrescription();
  }, [code]); // Re-run when the code changes

  // Functions to handle button clicks for downloading the app
  const handleButtonClick = () => {
    window.open(
        "https://play.google.com/store/apps/details?id=com.rxone&hl=en-IN",
      "_blank"
    );
  };

  const handleAppleClick = () => {
    window.open(
  "https://apps.apple.com/in/app/rxone-care-to-cure/id6479252685",
      "_blank"
    );
  };

  if (loading) return <p style={{display: "flex", alignItems: "center", justifyContent: "center"}}>Loading...</p>;

  // Check for the "not_found" error and render the JSX content you want
  if (error ) {
    return (
      <div
        role="article"
        aria-roledescription="email"
        lang="en"
        className="email-container"
      >
        <table role="presentation" className="email-table">
          <tbody>
            <tr>
              <td align="center" className="email-td">
                <table role="presentation" className="inner-table">
                  <tbody>
                    <tr>
                      <td className="logo-td">
                        <a href="https://rxone.app" className="logo-link">
                          <img
                            src="https://rxone-static.s3.ap-south-1.amazonaws.com/images/rxone_white.png"
                            alt="RxOne Logo"
                            className="logo-img"
                          />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="content-td">
                        <h1>Oh ho, Link is Broken!</h1>
                        <a href="https://rxone.app" className="content-link">
                          <img
                            src="https://rxone-static.s3.ap-south-1.amazonaws.com/images/broken-link-dribble.gif"
                            alt=""
                            className="content-img"
                          />
                        </a>
                        <div style={{ textAlign: "center" }}>
                          <p
                            style={{
                              textAlign: "center",
                              fontSize: "17px",
                              fontWeight: "600",
                            }}
                          >
                            We kindly encourage you to visit our app, where all
                            your medical records are securely stored. Simply
                            navigate to the “Health File” section, and you will
                            be able to access your files with ease.
                          </p>
                          <button
                            type="button"
                            className="getapp-container"
                            onClick={handleButtonClick}
                          >
                            <img
                              src={require("../Images/googleplaystore-icon.png")}
                              alt="Get the app on Google Play"
                            />
                          </button>
                          <button
                            type="button"
                            className="getapp-contain"
                            onClick={handleAppleClick}
                            style={{ margin: "8px" }}
                          >
                            <img
                              src={require("../Images/Appleicon.png")}
                              alt="Get the app on Apple Play"
                            />
                          </button>
                        </div>
                        <p>Click to close the window:</p>
                        <div className="button-container">
                          <button
                            id="close"
                            onClick={() => window.close()}
                            className="close-button"
                          >
                            CLOSE
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="footer-td">
                        <p>
                          <a href="https://www.facebook.com/rxonecare">
                            <img
                              src="https://rxone-static.s3.ap-south-1.amazonaws.com/images/facebook2x.png"
                              alt="Facebook"
                              className="social-icon"
                            />
                          </a>
                          <a href="https://twitter.com/RxOneCare">
                            <img
                              src="https://rxone-static.s3.ap-south-1.amazonaws.com/images/twitter2x.png"
                              alt="Twitter"
                              className="social-icon"
                            />
                          </a>
                          <a href="https://www.linkedin.com/company/rx-one">
                            <img
                              src="https://rxone-static.s3.ap-south-1.amazonaws.com/images/linkedin2x.png"
                              alt="LinkedIn"
                              className="social-icon"
                            />
                          </a>
                        </p>
                        <p>
                          RxOne © Rx One Care 2024
                          <br />
                          <a
                            href="mailto:support@RxOne.app"
                            className="unsub-link"
                          >
                            support@RxOne.app
                          </a>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  // Render general error message if there's another error
  if (error) return <p>Error: {error}</p>;

  // Default render
  return (
    <div className="prescription-container max-w-4xl mx-auto p-4">
      {data && <div>{/* Render prescription data here if needed */}</div>}
    </div>
  );
};

export default Prescription;
