import React from "react";
import { useMediaQuery } from "@material-ui/core";


const CenteredAlert = ({ message, onClose }) => {
  const isMobile = useMediaQuery("(max-width:710px)");

  const alertStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "8px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.5)", // Increased box shadow
    zIndex: 9999,
    maxWidth:isMobile? "100%": "80%", // Adjusted max-width value
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Center content horizontally
    textAlign: "center", // Center text content
    width: isMobile? "100%" : "none",
  };


  const buttonStyle = {
    padding: "10px 20px",
    backgroundColor: "#199a8e",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    outline: "none",
    marginTop: "20px", // Add some space between the message and the button
  };

  return (
    <div style={alertStyle}>
      <p style={{fontSize: "22px", fontWeight: "500"}}>{message}</p>
      <button onClick={onClose} style={buttonStyle}>
        OK
      </button>
    </div>
  );
};

export default CenteredAlert;