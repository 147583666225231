import { useState, useEffect, useRef } from "react";
import backgroundimg from "../Images/banner-bg.jpg";
import emailjs from "emailjs-com";
import { countriesList } from "../utility/constants";
import { useMediaQuery } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { medicals } from "./config";
import NukaCarousel from "../tools/NukaCarousel";
import { faqs } from "./config";
import { faqsss } from "./config";
import docgirl from "../Images/home/medicbot.png";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
  salutationFormControl: {
    width: "27%", // Adjust the width of the salutation select
  },
  gender: {
    width: "30%",
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  nameField: {
    background: "white",
  },
  select: {
    border: "none", // Remove border
    width: "50px", // Adjust the width as needed
  },
}));

const Medine = () => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [pin, setPin] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const isMobile = useMediaQuery("(max-width:710px)");
  const isValidPhoneNumber = (phoneNumber) => {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
    return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
  };
  const [expandedQuestions, setExpandedQuestions] = useState([]);

  const handlesubmit = (e) => {
    e.preventDefault();
    const serviceid = "service_7yxtqsn";
    const templateid = "template_x3wusd7";
    const publickey = "4UpyJJj3P5JGeMZFz";
    const messageContent = `
    Type: 'Medicine Order'
    Patient Name: ${name}
    Phone: ${phoneCode}${phone}
    City: ${city}
    Address: ${message}
    Pin: ${pin}

  `;

    const templateparams = {
      from_name: name,
      from_email: email,
      to_name: "web",
      message: messageContent, // Include the messageContent here
    };

    emailjs
      .send(serviceid, templateid, templateparams, publickey)
      .then((response) => {
        console.log("email set successfully", response);
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.log("error sending email", error);
      });
  };

  const toggleQuestion = (id) => {
    if (expandedQuestions.includes(id)) {
      setExpandedQuestions(expandedQuestions.filter((qid) => qid !== id));
    } else {
      setExpandedQuestions([...expandedQuestions, id]);
    }
  };

  const titlle =
    "FREE Treatment Advice - Consult for Medine Treatment Advice - Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, Mental Health | RxOne";
  const desc =
    "Get free treatment advice and consult for medical treatments including Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, and Mental Health at RxOne.";
  return (
    <>
      <Helmet>{<title>{titlle}</title>}</Helmet>
      <Helmet>{<meta name="description" content={desc} />}</Helmet>

      <section data-aos-duration="3000" className="ms-cureways-section">
      <div
          style={{
            padding: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row", // Change flex direction based on screen size
            marginTop: isMobile ? "1rem" : "0",
            marginTop: "4rem",
            marginBottom: "-7rem"
          }}
        >
          <div style={{marginLeft: isMobile? "0": "8rem"}}>
            <h1
              className="ms-font-weight-800"
              style={{ color: "#fff", fontSize: isMobile? "42px": "3rem" , fontWeight: "800"}}
            >
              Get upto 30% discount
            </h1>
            <div className=" mb-3" style={{ textAlign: "center" }}>
              <p
                className=" fw-normal"
                style={{ color: "#fff", fontWeight: "bold", fontSize: isMobile? "30px": "3rem", marginTop: ".5rem" }}
              >
                On Medicine Orders
              </p>
              <div style={{ textAlign: 'center' }}>
  <span style={{ color: '#fff', display: 'block',fontSize: isMobile? "21px": "24px", marginTop: '-.5px', fontWeight: "500" }}>
  Fill up the form or call us at 
  </span>
  <span style={{ fontSize: isMobile? "16px": "21px", color: '#fff', display: 'block', fontWeight: 'bold' }}>
  +91-770-393-4446 to place your order now
  </span>

            <img src={docgirl} style={{textAlign: "center", marginTop: "-2rem"}} />
</div>
            </div>
          </div>
          <div style={{ float: "inline-end" }}>
            <Container component="main" maxWidth="xs">
              <div
                className="provider__revolutionize__item"
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  borderRadius: "55px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <form
                  style={{ textAlign: "center", width: "133%" }}
                  onSubmit={handlesubmit}
                >
                  <TextField
                    id="firstName"
                    name="first_name"
                    label="Full Name"
                    variant="filled"
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    fullWidth
                    className={classes.nameField}
                  />
                  <FormControl
                    variant="filled"
                    fullWidth
                    className={classes.nameField}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="w-23"
                        value={phoneCode} // Concatenate phoneCode and phone here
                        classes={{ select: classes.select }}
                      >
                        {countriesList &&
                          countriesList.map((item, index) => (
                            <MenuItem
                              value={item.code}
                              key={item.code + "-" + index}
                            >
                              {item.code}
                            </MenuItem>
                          ))}
                      </Select>

                      <InputLabel
                        htmlFor="phoneNumber"
                        style={{ marginLeft: "101px" }}
                      >
                        Phone Number*
                      </InputLabel>
                      <Input
                        id="phoneNumber"
                        name="phone"
                        label="Phone Number"
                        required
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        error={phoneError}
                        style={{ flex: 1, marginLeft: 6, width: "100px" }} // Adjust the margin and flex properties
                        className={classes.nameField}
                      />
                    </div>
                    {phoneError && (
                      <FormHelperText error>
                        Please enter a valid phone number.
                      </FormHelperText>
                    )}
                  </FormControl>
          

                  <TextField
                    id="chiefComplaints"
                    name="message"
                    label="Address"
                    variant="filled"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    fullWidth
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    className={classes.nameField}
                  />
                          <TextField
                    id="pin"
                    name="pin"
                    label="Pin Code"
                    variant="filled"
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    required
                    fullWidth
                    className={classes.nameField}
                  />

               
                  <TextField
                    id="city"
                    name="city"
                    label="Enter your city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    variant="filled"
                    required
                    fullWidth
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    className={classes.nameField}
                  />
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </Container>
          </div>
        </div>
      </section>
      <section
      style={{
        background: "linear-gradient(120deg, rgb(237, 233, 233), #edf2f7)",
      }}
      className="ms-apart-section"
    >
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-12 text-center">
            <h2 className="ms-font-weight-600 ms-primary-color">
              How it works?
            </h2>
          </div>
        </div>
        <div className="row g-9 justify-content-center">
          {medicals?.length > 0 ? (
            medicals.map((item, index) => (
              <div className="col-md-4 col-sm-6 mb-4" key={index}>
                <div className="provider__revolutionize__item h-100">
                  <div className="provider__revolutionize__icon text-center">
                    <img src={item?.icon} alt={item?.alt} />
                  </div>
                  <h4 className="provider__revolutionize__title text-center ms-font-weight-600 mt-3 mb-4 ms-primary-color">
                    {item?.title}
                  </h4>
                  <h6 className="provider__revolutionize__text ms-2 mb-0 fw-normal text-center">
                    {item?.text}
                  </h6>
                </div>
              </div>
            ))
          ) : (
            <div className="col-12 text-center">No items to display</div>
          )}
        </div>
      </div>
    </section>

      <section className="ms-content-section">
            <div className="container">
                        <h3 className="mb-4 ms-font-weight-600 ms-primary-color">Terms & Conditions
                        </h3>
                        <h6 className="fw-normal" style={{color: "black"}}>The Pharmaceutical Drugs and Services shall be delivered by the Third Party Pharmacy or independent contractors. You accept and acknowledge that the Third Party Pharmacy or such other transporter/ courier/ delivery personnel, engaged by the Third Party Pharmacy or RxOne, shall be independent contractors in-charge of the delivery of the Pharmaceutical Drugs and Services from the concerned Third Party Pharmacy to the address notified by You, with no control over the Pharmaceutical Drugs and Services and no additional obligation apart from standard delivery obligations and duty of care.
                        </h6>
                        <br/>
                        <h6 className="fw-normal" style={{color: "black"}}>You further accept and acknowledge that RxOne does not engage in the distribution of the Pharmaceutical Drugs and Services, and may provide the services of a third party transporter/ courier delivery personnel for the purposes of delivery of Pharmaceutical Drugs and Services from the concerned Third Party Pharmacy to the address notified by You.
                        </h6>
                        <br />
          <h6 className="fw-normal" style={{color: "black"}}>
          The following are our delivery charges:
          </h6>
          <h6 className="fw-normal" style={{color: "black"}}>Delivery charges for ALL orders: Rs. 25</h6>
          <h6 style={{color: "black", fontWeight: "700"}}>
          *FREE Delivery on Medicine orders of Rs. 1000 or more.
          </h6>
               
            </div>
        </section>

        <h5 className="mb-4 ms-font-weight-600 ms-primary-color" style={{ marginLeft: "1rem", fontSize: "1.4rem" }}>
        Frequently Asked Questions
      </h5>

      <ul style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
        {faqsss.map((faq) => (
          <li className="languages card" key={faq.id}>
            <div>
              <strong>{faq.question}</strong>
              <button
                onClick={() => toggleQuestion(faq.id)}
                style={{
                  border: "none",
                  background: "none",
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  marginTop: "-22px",
                }}
              >
                <span>{expandedQuestions.includes(faq.id) ? "▲" : "▼"}</span>
              </button>
            </div>
            {expandedQuestions.includes(faq.id) && <p>{faq.answer}</p>}
          </li>
        ))}
      </ul>
    </>
  );
};
export default Medine;
