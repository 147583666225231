import react, { createContext,useState } from "react";


const GlobelContext  = createContext();
export const GlobelProvider = ({children}) =>{
   const [isChatBot, setChatBot] = useState(false);
   return (
       <GlobelContext.Provider value={[isChatBot, setChatBot]}>
           {children}
       </GlobelContext.Provider>
   )
    
}

export default GlobelContext;