import React, { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import "../Css/treatmenthome.css";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ServiceCall from "../ServiceCall";
import CircularProgress from "@material-ui/core/CircularProgress"; // Import loader component

// Import images
import img1 from "../Images/home/caa.png";
import img2 from "../Images/home/plastic surgery (1).png";
import img3 from "../Images/home/dental (1).png";
import img4 from "../Images/home/ent.png";
import img5 from "../Images/home/gass.png";
import img6 from "../Images/home/genral surgery.png";
import img7 from "../Images/home/gynecology (1).png";
import img8 from "../Images/home/liver1.png";
import img9 from "../Images/home/hematology.png";
import img10 from "../Images/home/ivf (1).png";
import img11 from "../Images/home/neurology (1).png";
import img12 from "../Images/home/baiatric.png";
import img13 from "../Images/home/oncoogy.png";
import img14 from "../Images/home/opthalmology.png";
import img15 from "../Images/home/orthopedics.png";
import img16 from "../Images/home/routine checkup.png";
import img17 from "../Images/home/spine (1).png";
import img18 from "../Images/home/transplant.png";
import img19 from "../Images/home/urology.png";
import img20 from "../Images/home/vascular surgery.png";

const TreatmentHome = () => {
  const [treatdata, setTreatdata] = useState([]);
  const [departdata, setDepartdata] = useState([]);
  const [showModals, setShowModals] = useState(false);
  const [loading, setLoading] = useState(true); // Main data loading
  const [modalLoading, setModalLoading] = useState(false); // Modal data loading
  const [loadingItem, setLoadingItem] = useState(null); // Track loading for specific rectangle
  const navigate = useNavigate();

  useEffect(() => {
    const treatmentdata = async () => {
      try {
        const res = await ServiceCall.gett("rxone/fetch/distinct/treatment/departments");
        if (res) {
          setTreatdata(res?.data?.departments);
          setLoading(false);
        }
      } catch (err) {
        console.error("Error fetching treatment data:", err);
        setLoading(false);
      }
    };
    treatmentdata();
  }, []);

  const handletreat = (department) => async (e) => {
    e.preventDefault();
    setLoadingItem(department); // Set loading state for the clicked item
    setModalLoading(true); // Show modal loader

    try {
      const res = await ServiceCall.gett(`/rxone/fetch/all/treatment/departments/${department}`);
      if (res) {
        setDepartdata(res?.data?.departments || []);
        setShowModals(true);
      }
    } catch (err) {
      console.error("Error fetching department data:", err);
      setDepartdata([]);
    } finally {
      setLoadingItem(null); // Clear the loader for the clicked item
      setModalLoading(false); // Hide the modal loader
    }
  };

  const handleProcedureClick = (routeKey) => {
    setShowModals(false); // Close the modal
    navigate(`/treatments/${routeKey}`, { state: { routekey: routeKey } });
  };

  const getDepartmentImage = (department) => {
    switch(department) {
      case "CARDIOLOGY AND CARDIAC SURGERY": return img1;
      case "COSMETIC AND PLASTIC SURGERY": return img2;
      case "DENTAL TREATMENT": return img3;
      case "ENT SURGERY": return img4;
      case "GASTROENTEROLOGY": return img5;
      case "GENERAL SURGERY": return img6;
      case "GYNECOLOGY": return img7;
      case "HEPATOLOGY": return img8;
      case "Hematology": return img9;
      case "IVF and INFERTILITY": return img10;
      case "Neurology and Neurosurgery": return img11;
      case "OBESITY OR BARIATRIC SURGERY": return img12;
      case "ONCOLOGY AND ONCOSURGERY": return img13;
      case "OPTHALMOLOGY": return img14;
      case "ORTHOPDICS": return img15;
      case "ROUTINE HEALTH CHECK-UPS": return img16;
      case "Spine Surgery": return img17;
      case "TRANSPLANT SURGERY": return img18;
      case "UROLOGY TREATMENT": return img19;
      case "VASCULAR SURGERY": return img20;
      default: return img2; // Default image
    }
  };

  return (
    <>
      <section className="ms-book-banner d-flex align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="offset-md-3 col-md-6" style={{ height: "5px" }}>
              <h3 className="mb-3 ms-font-weight-600 text-center"></h3>
            </div>
          </div>
        </div>
      </section>

      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress />
        </div>
      ) : (
        <section className="treatment-home">
          <div className="heading-description">
            <h2>Treatment Options</h2>
            <p>
              Explore a variety of treatment options designed to meet your needs with top medical experts and care by best facilities in India and Worldwide.
            </p>
          </div>

          <div className="rectangle1-container">
            {treatdata?.map((item, index) => {
              const departmentImage = getDepartmentImage(item);

              return (
                <div key={index} className="rectangle1" onClick={handletreat(item)}>
                  {loadingItem === item ? (
                    <CircularProgress size={24} /> // Loader appears inside rectangle if clicked
                  ) : (
                    <>
                      <div className="plus-icon">
                        <AddIcon />
                      </div>
                      <div className="logo">
                        <img src={departmentImage} alt={item} />
                      </div>
                      <div className="info">
                        <div className="name">{item || "Learn More"}</div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </section>
      )}

      <Modal show={showModals} onHide={() => setShowModals(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ width: "100%", textAlign: "center", color: "#005c74", fontSize: "1.4rem", fontWeight: "600" }}>
            Click to view
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalLoading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "150px" }}>
              <CircularProgress />
            </div>
          ) : (
            <div className="procedure-list">
              {departdata.map((item, index) => (
                <div key={index} className="procedure-item" onClick={() => handleProcedureClick(item.route_key)} style={{
                  padding: "12px 20px", margin: "8px 0", borderRadius: "8px", background: "#f8f9fa", cursor: "pointer",
                  transition: "all 0.3s ease", color: "#333" }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.background = "#005C74";
                    e.currentTarget.style.color = "#fff";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.background = "#f8f9fa";
                    e.currentTarget.style.color = "#333";
                  }}
                >
                  <h4 style={{ margin: 0, fontSize: "1.1rem" }}>{item.procedure}</h4>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TreatmentHome;