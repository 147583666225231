import axios from "axios"
import React, { Component } from 'react';
import { constants } from "./utility/constants"


const baseURL = process.env.REACT_APP_RXOPD;
const docPortal = process.env.REACT_APP_DOCPORTAL
const patientPortal = process.env.REACT_APP_PATIENTPORTAL
const appMode = process.env.REACT_APP_M0DE;
//  const baseURL = `${baseURL}${constants.testPath("mode")!=='test'? '':'/'+constants.testPath("mode")}`;

class ServiceCall extends Component {
    static get(url, headers) {
        return axios.get(`${baseURL}${url}`, { headers });
    }
    static gett(url, headers) {
        return axios.get(`${baseURL}${url}`, { headers });
    }

    static delete(url, headers) {
        return axios.delete(`${baseURL}${url}`, { headers });
      }

    static put(url, option, headers) {
        return axios.put(`${baseURL}${url}`, option, { headers });
    }

    static post(url, option, headers) {
        return axios.post(`${baseURL}${url}`, option, { headers });
    }


    static images(url) {
        return `${baseURL}${url}`
    }

    static docProtalUrl(id) {
        return `${docPortal}${id}`
    }

    static patientProtalUrl(id) {
        return `${patientPortal}${id}`
    }
}

export default ServiceCall;