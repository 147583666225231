import React from "react";
import { Link } from "react-router-dom";
import "../../Css/companypage.css";
function PrivacyPolicy() {
  return (
    <>
      <section className="pp__first">
        <h1>Privacy Policy</h1>
        <p>
          Rx One Care Pvt. Ltd. (“us”, “we”, or “RxOne”, which also includes its
          affiliates) is the author and publisher of the internet resource
          www.rxone.app on the world wide web as well as the software and
          applications provided by RxOne, including but not limited to the
          mobile application ‘Rx One – Care to Cure’, and the software and
          applications of the brand names ‘RxOne’, ‘RxOne Care’, and ‘Care
          Portal’ (together with the Website, referred to as the “Platform”).
        </p>
        <br />
        <p>
          This privacy policy (&quot;Privacy Policy&quot;) explains how we, as
          controllers, collect, retain, use, manage, share, disclose and protect
          Personal information about the Users of the Platform, (referred to as
          “you” or “your” or “Users” in this Privacy Policy). We created this
          Privacy Policy to demonstrate our commitment to the protection of your
          privacy and your personal information. Your use of and access to the
          Platform is subject to this Privacy Policy and our Terms of Service.
          Any capitalized term used but not defined in this Privacy Policy shall
          have the meaning attributed to it in our Terms of Service. We suggest
          you read this in conjunction with our Terms of Service.
        </p>
        <br />
        <p>
          BY USING THE PLATFORM OR OTHERWISE GIVING US YOUR INFORMATION, YOU
          WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE PRACTICES
          AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY
          THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, RETENTION,
          MANAGING, USAGE, SHARING AND DISCLOSURE OF YOUR INFORMATION AS
          DESCRIBED IN THIS PRIVACY POLICY. THE PRIVACY POLICY AND THE TERMS AND
          CONDITIONS CONSTITUTE A LEGAL AGREEMENT BETWEEN THE RXONE CARE ANF YOU
          IN CONNECTION WITH THE USE AND ACCESS TO THE RESOURCES. IF YOU DO NOT
          AGREE WITH THIS PRIVACY POLICY AND/OR ANY POINT HEREIN, AT ANY TIME,
          PLEASE DO NOT USE ANY OF THE PLATFORM OR GIVE US ANY OF YOUR
          INFORMATION. IF YOU USE THE PLATFORM ON BEHALF OF A THIRD PARTY
          (INCLUDING YOUR RELATIVES, GUARDIANS, COMPANY REPRESENTATIVE AND/OR
          OTHERWISE), YOU REPRESENT THAT YOU ARE AUTHORISED BY SUCH INDIVIDUAL
          OR ENTITY TO (I) ACCEPT THIS PRIVACY POLICY ON SUCH INDIVIDUAL’S OR
          ENTITY’S BEHALF, AND (II) CONSENT ON BEHALF OF SUCH INDIVIDUAL OR
          ENTITY TO OUR COLLECTION, RETENTION, MANAGE, USE, SHARE AND DISCLOSURE
          OF SUCH INDIVIDUAL’S OR ENTITY’S INFORMATION AS DESCRIBED IN THIS
          PRIVACY POLICY.
        </p>

        <div className="pp__container">
          <ol type="1">
            {/* ------- why Privacy Policy --------       */}

            {/* <strong>WHY THIS PRIVACY POLICY</strong>  */}
            <br />
            <br />
            {/* <p>This Privacy Policy is published in compliance with, inter alia:</p> */}
            <ol type="I" className="pp__listGap">
              {/* <li>Section 43A of the Information Technology Act, 2000;</li> */}
              {/* <li>Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the “SPI Rules”);</li> */}
              {/* <li>Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011.</li> */}
              <p>
                <strong>This Privacy Policy states the following:</strong>
              </p>
              <li>
                The type of information collected from the Users including
                Sensitive Personal Data or Information;
              </li>
              <li>
                The purpose, means and modes of collection, usage, processing,
                retention and destruction of such information; and
              </li>
              <li>How and to whom RxOne will disclose such information.</li>
            </ol>
            <br />
            {/* ------------------ Collect person Information --- -- ------  */}

            <li>
              {" "}
              <strong>COLLECTION OF PERSONAL INFORMATION</strong>
              <ol type="" className="pp__listGap">
                <li>
                  When you access the Platform, or through any interaction with
                  us via emails, telephone calls or other correspondence, we may
                  ask you to voluntarily provide us with certain information
                  that personally identifies you or could be used to personally
                  identify you. You hereby consent to the collection of such
                  information by RxOne. Without prejudice to the generality of
                  the above, information collected by us from you may include
                  (but is not limited to) the following:
                </li>
                <ol type="a" className="pp__listGap">
                  <li>
                    Personal Information (such as Your Name, Age, Family
                    Information, etc);
                  </li>
                  <li>Login Credentials;</li>
                  <li>
                    contact data (such as your email ID, address and phone
                    number);
                  </li>
                  <li>
                    demographic data (such as your gender, your date of birth
                    and your pin code);
                  </li>
                  <li>
                    IP address, operating system, browser type and version,
                    browser configuration, name of internet service provider,
                    and other types of computer and connection related
                    information relevant to identifying your device type.
                    Connecting to the Platform, enabling data exchange with you
                    and your device, and ensuring a convenient use of the
                    Platform;
                  </li>
                  <li>
                    data regarding your usage of the services and history of the
                    appointments made by or with you through the use of
                    Platform;
                  </li>
                  <li>
                    insurance data (such as your insurance carrier and insurance
                    plan);
                  </li>
                  <li>Search terms entered;</li>
                  <li>
                    Information collected by cookies or similar technologies;
                  </li>
                  <li>
                    Subscriptions, enrolments for promotions, use of special
                    offers, etc;
                  </li>
                  <li>Medical Information;</li>
                  <li>Financial and payment-related data;</li>
                  <li>Geo-location information;</li>
                  <li>
                    Any other information that, you voluntarily choose to
                    provide to us (such as information shared by you with us
                    through emails or letters).
                  </li>
                </ol>
                <li>
                  RxOne reserves the right to use the information provided by
                  you for the following:
                </li>
                <ol type="a" className="pp__listGap">
                  <li>Publishing on Platform</li>
                  <li>Introducing you to new products/services of RxOne</li>
                  <li>Offer you new Products/services by RxOne Partners</li>
                  <li>Contacting you for feedback</li>
                  <li>Analysis and Industry Reporting</li>
                  <li>Improvement of our services</li>
                </ol>
                <li>
                  The information collected from you by RxOne may constitute
                  ‘personal information’ or ‘sensitive personal data or
                  information’ under the SPI Rules. “Personal Information” is
                  defined under the SPI Rules to mean any information that
                  relates to a natural person, which, either directly or
                  indirectly, in combination with other information available or
                  likely to be available to a body corporate, is capable of
                  identifying such person. The SPI Rules further define
                  “Sensitive Personal Data or Information” of a person to mean
                  information about that person relating to:
                </li>
                <ol type="a" className="pp__listGap">
                  <li>passwords;</li>
                  <li>
                    financial information such as bank accounts, credit and
                    debit card details or other payment instrument details;
                  </li>
                  <li>physical, physiological and mental health condition;</li>
                  <li>sexual orientation;</li>
                  <li>medical records and history;</li>
                  <li>biometric information;</li>
                  <li>
                    Information received by a body corporate under lawful
                    contract or otherwise;
                  </li>
                  <li>call data records.</li>
                </ol>
                <li>
                  RxOne will be free to collect, retain, use, manage, share and
                  disclose information that is freely available in the public
                  domain without your consent.
                </li>
              </ol>
            </li>
            <br />

            <li>
              {" "}
              <strong>PRIVACY STATEMENTS</strong>
              <ol type="" className="pp__PS">
                
                  <p>
                    <span>2.1</span> A condition of each User’s use of and
                    access to the Platform is their acceptance of the Terms of
                    Service, which also involves acceptance of the terms of this
                    Privacy Policy. Any User that does not agree with any
                    provisions of this Privacy Policy has the option to
                    discontinue the Services provided by RxOne immediately.
                  </p>
                  <p>
                    <span>2.2</span> An indicative list of information that
                    RxOne may require you to provide to enable your use of the
                    Platform is provided in the Schedule annexed to this Privacy
                    Policy.
                  </p>
                  <p>
                    <span>2.3</span> All the information provided to RxOne by a
                    User, including Personal Information or any Sensitive
                    Personal Data or Information, is voluntary. In accordance
                    with applicable laws in India (Digital Personal Data
                    Protection Act, 2023) and Africa (such as POPIA and NDPR),
                    you understand that RxOne may use certain information of
                    yours, which has been designated as Personal Information or
                    ‘Sensitive Personal Data or Information’ under relevant
                    privacy laws, for the following purposes:
                  </p>
                  <ol type="a" className="pp__listGap">
                    <li>Providing you the Services.</li>
                    <li>
                      Commercial purposes, in an aggregated or non-personally
                      identifiable form for research, statistical analysis, and
                      business intelligence purposes.
                    </li>

                    <li>
                      Sale or transfer of such research, statistical, or
                      intelligence data in an aggregated or non-personally
                      identifiable form to third parties and affiliates.
                    </li>

                    <li>
                      Communication purposes, including providing you a better
                      way of booking appointments and for obtaining feedback in
                      relation to the Practitioners and their practice.
                    </li>

                    <li>Debugging customer support-related issues.</li>

                    <li>
                      Contacting you to complete any transaction if you do not
                      complete a transaction after providing your contact
                      information.
                    </li>
                  </ol>
                  <br />

                  <p>
                    <span>2.4</span> RxOne also reserves the right to use
                    information provided by or about the User for the following
                    purposes:
                  </p>
                  <ol type="a" className="pp__listGap">
                    <li>Publishing such information on the Platform.</li>
                    <li>Contacting Users to offer new products or services.</li>
                    <li>
                      Contacting Users to take product and Service feedback.
                    </li>
                    <li>
                      Analyzing software usage patterns for improving product
                      design and utility.
                    </li>
                    <li>
                      Analyzing anonymized practice information for commercial
                      use.
                    </li>
                    <li>
                      Processing payment instructions, including through
                      independent third-party service providers such as payment
                      gateways, banking institutions, pre-paid instrument and
                      wallet providers.
                    </li>
                  </ol>
                  <p>
                    <span>2.5</span> If you have voluntarily provided your
                    Personal Information to RxOne for any of the purposes stated
                    above, you hereby consent to such collection and use of such
                    information in compliance with local laws, such as the
                    Digital Personal Data Protection Act, 2023 (India) and
                    POPIA/NDPR (Africa). RxOne will not contact you on your
                    registered telephone number(s) if such numbers are listed on
                    a Do Not Call (DNC) registry under local law without your
                    express consent.
                  </p>
                  <p>
                    <span>2.6</span> Collection, use, and disclosure of
                    information designated as Personal Information or Sensitive
                    Personal Data under the India SPI Rules or Africa&#39;s
                    POPIA/NDPR require your express consent. By affirming your
                    consent to this Privacy Policy, you provide consent for such
                    collection, use, and disclosure as required under applicable
                    law.
                  </p>
                  <p>
                    <span>2.7</span> RxOne does not control or endorse the
                    content, messages, or information found in any Platform and,
                    therefore, specifically disclaims liability with regard to
                    such content. You agree to release RxOne from any claims
                    related to third-party content, as applicable under local
                    laws.
                  </p>
                  <p>
                    <span>2.8</span>Users are responsible for maintaining the
                    accuracy of the information they submit. If your personal
                    information changes, you may correct, delete inaccuracies,
                    or amend information by contacting us at
                    contact_us@rxone.app. If RxOne has reasonable grounds to
                    suspect that information provided by you is inaccurate,
                    RxOne may discontinue providing Services. Certain data, such
                    as evaluative data or prosecution-related data may not be
                    updated under local law.
                  </p>
                  <p>
                    <span>2.9</span> If you wish to cancel your account or
                    request that we no longer use your information, contact us
                    at contact_us@rxone.app. Data will be retained as required
                    under India’s Digital Personal Data Protection Act or
                    Africa’s POPIA/NDPR. After retention periods expire, your
                    data may be anonymized and used solely for analytics
                    purposes.
                  </p>
                  <p>
                    <span>2.10</span> To opt-out of receiving non-essential
                    communications, including marketing-related information,
                    please send an email to contact_us@rxone.app.
                  </p>
                  <p>
                    <span>2.11</span> RxOne collects data such as IP addresses
                    and browser details for analysis purposes, but these will
                    not be shared with third parties in personally identifiable
                    form. Anonymized data may be shared with strategic partners,
                    investors, or advertisers under applicable laws in India and
                    Africa.
                  </p>
                  <p>
                    <span>2.12</span> Cookies and tracking technologies are used
                    to store data for technical administration. In compliance
                    with India’s Information Technology Rules and Africa&#39;s
                    POPIA, we ensure that cookies do not store Personal
                    Information. Users may disable cookies, though this may
                    limit features on the Platform.
                  </p>
                  <p>
                    <span>2.13</span> Unregistered Users may access limited
                    features by providing basic contact information (e.g., name,
                    phone number). Full access to features requires the creation
                    of an account, which involves providing Personal
                    Information.
                  </p>
                  <p>
                    <span>2.14</span>
                    RxOne is not responsible for third-party sites linked
                    through its Platform. We encourage Users to review the
                    privacy policies of any third-party websites they visit.
                  </p>
                  <p>
                    <span>2.15</span> RxOne disclaims any liability for
                    information shared by Users on public forums. Information
                    posted by moderators or administrators does not necessarily
                    reflect the views of RxOne.
                  </p>
                  <p>
                    <span>2.16</span> RxOne does not collect personal
                    information from public records, except as necessary for
                    registration purposes.
                  </p>
                  <p>
                    <span>2.17</span> RxOne maintains a strict
                    &quot;No-Spam&quot; policy. We do not share your email
                    address with third parties without your explicit consent.
                  </p>
                  <p>
                    <span>2.18</span> RxOne follows reasonable security
                    practices and has established a comprehensive security
                    program under India’s DPDP Act and Africa&#39;s POPIA.
                  </p>
                  <p>
                    <span>2.19</span> RxOne discloses personal information only
                    when legally required by authorities under the India SPI
                    Rules, POPIA, or NDPR.
                  </p>
               
              </ol>
            </li>

            <br />
            <li>
              {" "}
              <strong>CASUAL VISITORS NOTE:</strong>
              <br />
              <br />
              <ol className="pp__cas">
                <p>
                  <span>3.1</span> {""}No sensitive personal data or information
                  is automatically collected by RxOne from any casual visitors
                  of this Platform, who are merely perusing the Platform.
                </p>

                <p>
                  <span>3.2</span> {""}Nevertheless, certain provisions of this
                  Privacy Policy are applicable to even such casual visitors,
                  and such casual visitors are also required to read and
                  understand the privacy statements set out herein, failing
                  which they are required to leave this Platform immediately.
                </p>

                <p>
                  <span>3.3</span> {""}If you, as a casual visitor, have
                  inadvertently browsed any other page of this Platform prior to
                  reading the privacy statements set out herein, and you do not
                  agree with the manner in which such information is obtained,
                  collected, processed, stored, used, disclosed or retained,
                  merely quitting this browser application should ordinarily
                  clear all temporary cookies installed by RxOne. All visitors,
                  however, are encouraged to use the “clear cookies”
                  functionality of their browsers to ensure such clearing /
                  deletion, as RxOne cannot guarantee, predict or provide for
                  the behaviour of the equipment of all the visitors of the
                  Platform.
                </p>

                <p>
                  <span>3.4</span> {""}You are not a casual visitor if you have
                  willingly submitted any personal data or information to RxOne
                  through any means, including email, post or through the
                  registration process on the Platform. All such visitors will
                  be deemed to be, and will be treated as, Users for the
                  purposes of this Privacy Policy, and in which case, all the
                  statements in this Privacy Policy apply to such persons.
                </p>
              </ol>
            </li>
            <br />
            <li>
              {" "}
              <strong>CONFIDENTIALITY AND SECURITY</strong>
              <br />
              <br />
              <ol className="pp__cas">
                <p>
                  <span>4.1</span> {""}NYour Personal Information is maintained
                  by RxOne in electronic form on Amazon Web Service’s (AWS)
                  utility based cloud. These servers are in compliance with The
                  Health Insurance Portability and Accountability Act of 1996
                  (HIPAA) for protecting the security and privacy of protected
                  health information (PHI).
                </p>

                <p>
                  <span>4.2</span> {""}Such information may also be converted to
                  physical form from time to time. RxOne takes all necessary
                  precautions to protect your personal information both online
                  and off-line, and implements reasonable security practices and
                  measures including certain managerial, technical, operational
                  and physical security control measures that are commensurate
                  with respect to the information being collected and the nature
                  of RxOne’s business.
                </p>

                <p>
                  <span>4.3</span> {""}No administrator at RxOne will have
                  knowledge of your password. It is important for you to protect
                  against unauthorized access to your password, your computer
                  and your mobile phone. Be sure to log off from the Platform
                  when finished. RxOne does not undertake any liability for any
                  unauthorised use of your account and password. If you suspect
                  any unauthorized use of your account, you must immediately
                  notify RxOne by sending an email to support@Rxone.app You
                  shall be liable to indemnify RxOne due to any loss suffered by
                  it due to such unauthorized use of your account and password.
                </p>

                <p>
                  <span>4.4</span> {""}RxOne makes all User information
                  accessible to its employees, agents or partners and third
                  parties only on a need-to-know basis, and binds only its
                  employees to strict confidentiality obligations.
                </p>
                <p>
                  <span>4.5</span> {""}Part of the functionality of RxOne is
                  assisting the healthcare providers to maintain and organise
                  such information. RxOne may, therefore, retain and submit all
                  such records to the appropriate authorities, or to healthcare
                  providers who request access to such information.
                </p>
                <p>
                  <span>4.6</span> {""}Part of the functionality of the RxOne is
                  assisting the Users to access information relating to them.
                  RxOne may, therefore, retain and submit all such records to
                  the relevant Users, or to their healthcare providers.
                </p>
                <p>
                  <span>4.7</span> Notwithstanding the above, RxOne is not
                  responsible for the confidentiality, security or distribution
                  of your Personal Information by our partners and third parties
                  outside the scope of our agreement with such partners and
                  third parties. Further, RxOne shall not be responsible for any
                  breach of security or for any actions of any third parties or
                  events that are beyond the reasonable control of RxOne
                  including but not limited to, acts of government, computer
                  hacking, unauthorised access to computer data and storage
                  device, computer crashes, breach of security and encryption,
                  poor quality of Internet service or telephone service of the
                  User etc.
                </p>
              </ol>
            </li>
            <br />
            <li>
              {" "}
              <strong>CHANGE TO PRIVACY POLICY</strong>
              <br />
              <br />
              <ol className="pp__cas">
                <p>
                  <span>5.1</span> {""}RxOne may update this Privacy Policy at
                  any time, with or without advance notice. In the event there
                  are significant changes in the way RxOne treats User’s
                  personally identifiable information, or in the Privacy Policy
                  itself, RxOne will display a notice on the Platform or send
                  Users an email, as provided for above, so that you may review
                  the changed terms prior to continuing to use the Platform.
                </p>

                <p>
                  <span>5.2</span> {""}As always, if you object to any of the
                  changes to our terms, and you no longer wish to use the
                  Platform, you may contact support@rxone.app to deactivate your
                  account.
                </p>

                <p>
                  <span>5.3</span> {""}Unless stated otherwise, RxOne’s current
                  Privacy Policy applies to all information that RxOne has about
                  you and your account. If a User uses the Services or accesses
                  the Platform after a notice of changes has been sent to such
                  User or published on the Platform, User hereby provides
                  his/her/its consent to the changed terms.
                </p>
              </ol>
            </li>
            <br />
            <li>
              {" "}
              <strong>CHILDREN&#39;S AND MINOR&#39;S PRIVACY</strong>
              <br />
              <br />
              <ol className="pp__cas">
                <p>
                  <span>6.1</span> {""}RxOne strongly encourages parents and
                  guardians to supervise the online activities of their minor
                  children and consider using parental control tools available
                  from online services and software manufacturers to help
                  provide a child-friendly online environment.
                </p>

                <p>
                  <span>6.2</span> {""}These tools also can prevent minors from
                  disclosing their name, address, and other personally
                  identifiable information online without parental permission.
                </p>

                <p>
                  <span>6.3</span> {""}Although the RxOne Platform and Services
                  are not intended for use by minors, RxOne respects the privacy
                  of minors who may inadvertently use the internet or the mobile
                  application.
                </p>
              </ol>
            </li>
            <br />
            <li>
              {" "}
              <strong>CONSENT TO THIS POLICY</strong>
              <br />
              <br />
              <ol className="pp__cas">
                <p>
                  <span>7.1</span> {""}You acknowledge that this Privacy Policy
                  is a part of the Terms of Service of the Platform and the
                  other Services, and you unconditionally agree that becoming a
                  User of the Platform and its Services signifies your (i)
                  assent to this Privacy Policy, and (ii) consent to RxOne
                  using, collecting, processing and/or disclosing your Personal
                  Information in the manner and for the purposes set out in this
                  Privacy Policy.
                </p>

                <p>
                  <span>7.2</span> {""}Your visit to the Platform and use of the
                  Services is subject to this Privacy Policy and the Terms of
                  Service.
                </p>
              </ol>
            </li>
            <br />
            <li>
              {" "}
              <strong>ADDRESS FOR PRIVACY QUESTIONS</strong>
              <br />
              <br />
              <ol className="pp__cas">
                <p>
                  <span>8.1</span> {""}Should you have questions about this
                  Privacy Policy or RxOne&#39;s information collection, use and
                  disclosure practices, you may contact, the Data Protection
                  Officer appointed by RxOne in accordance with the provisions
                  of PDPA.
                </p>

                <p>
                  <span>8.2</span> {""}We will use reasonable efforts to respond
                  promptly to any requests, questions or concerns, which you may
                  have regarding our use of your personal information.
                </p>
                <p>
                  <span>8.3</span> {""}If you have any grievance with respect to
                  our use of your information, you may communicate such
                  grievance to the Data Protection Officer:
                </p>
                <p>
                  Name: Nitin Guru Address: Rx One Care Pvt Ltd Hartron
                  Innovation Centre, Plot No1, Udyog Vihar, Phase I, Dundahera
                  Village, Sector 20, Gurugram, Haryana 122022, India Phone:
                  +917703934446 Email: privacy@rxone.app
                </p>
              </ol>
            </li>
            <br />
          </ol>

          <div className="schdule">
            <h3>SCHEDULE</h3>
            <h4>Indicative List of Information by Nature of Service</h4>
            <ul>
              <li>
                <strong>
                  Users using the Platform by registering for an account on the
                  Platform:
                </strong>
                <p>
                  You can create an account by giving us information regarding
                  your [name, mobile number, email address], and such other
                  information as requested on the User registration page. This
                  is to enable us to provide you with the facility to use the
                  account to book your appointments and store other health
                  related information. Against every healthcare provider listed
                  in rxone.app, you may see a ‘show number’ option. When you
                  choose this option, you choose to call number through a free
                  telephony service provided by RxOne, and the records of such
                  calls are recorded and stored in Rx Labs’s servers. Such call
                  will have an IVR message stating the purpose of recording your
                  calls and your consent to such recordings are dealt with as
                  per the Privacy Policy. If you choose not to consent to
                  recording of such call, RxOne hereby reserves right to not
                  provide you the Services for which such Personal Information
                  is sought. Such records are dealt with only in accordance this
                  Privacy Policy.
                </p>
              </li>
              <li>
                <strong>
                  Users using the Platform without registering for an account on
                  the Platform:
                </strong>
                <p>
                  You can use the Platform without registering for an account,
                  but to book an appointment, you may be asked certain
                  information (including your [mobile number], and such other
                  information as requested when you choose to use the Services
                  without registration) to confirm the appointment. Against
                  every Practitioner listed in Rxone.app, you may see a ‘show
                  number’ option. When you choose this option, you choose to
                  call the number through a free telephony service provided by
                  RxOne, and the records of such calls are recorded and stored
                  in RxOne’s servers. Such call will have an IVR message stating
                  the purpose of recording your calls and your consent to such
                  recordings which are dealt with as per the Privacy Policy. If
                  you choose not to consent to recording of such call, RxOne
                  hereby reserves the right to not provide you the Services for
                  which such Personal Information is sought. Such records are
                  dealt with only in accordance with this Privacy Policy.
                </p>
                <br />
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
