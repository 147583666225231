import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom"; // Import useNavigate
import "../Css/Header.css";
import header__logo from "../Images/SVG/header logo.svg";
import header__logo1 from "../Images/home/output-onlinepngtools (1).png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Login from "./user/Login";
import ServiceCall from "../ServiceCall";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
function Header() {
  const style = {
    // Adding media querry..
    "@media (max-width: 700px)": {
      display: "none",
    },
  };

  const navigate = useNavigate();

  const [islogin, setlogin] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const { som } = useParams();

  const [nav, isNav] = useState(false);
  const [swap, setSwap] = useState("Download App");
  const media = window.matchMedia("(max-width: 768px)").matches;
  const [specialties, setSpecialties] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [doctordata, setDoctordata] = useState("");
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);
  // const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [treatdata, setTreatdata] = useState([]);
  const [departdata, setDepartdata] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [showProceduresDropdown, setShowProceduresDropdown] = useState(false);

  console.log(234256, departdata);
  const [procedures, setProcedures] = useState([]);
  const [showProcedures, setShowProcedures] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [lookup, setlookup] = useState("");

  const transitionNav = () => {
    if (window.scrollY > 100) {
      isNav(true);
    } else {
      isNav(false);
    }
  };

  useEffect(() => {
    if (!islogin) {
      document.body.style.overflowY = "scroll";
    }
    window.addEventListener("scroll", transitionNav);
    return () => window.addEventListener("scroll", transitionNav);
  });

  useEffect(() => {
    const lookups = async () => {
      try {
        const lookupdata = await ServiceCall.get(
          "/v2/facility/som/open/lookup"
        );
        if (lookupdata) {
          setlookup(lookupdata.data.records);
        }
      } catch (err) {}
    };
    lookups();
  }, []);

  useEffect(() => {
    const treatmentdata = async () => {
      try {
        const res = await ServiceCall.gett(
          "rxone/fetch/distinct/treatment/departments"
        );
        if (res) {
          setTreatdata(res?.data?.departments);
        }
      } catch (err) {}
    };
    treatmentdata();
  }, []);
  const filterDoctorsBySpecialty = (specialty) => {
    navigate(`/specialists/${specialty.code}`, {});
    // setShowDropdown(false);
  };

  const [showMobileDropdown, setShowMobileDropdown] = useState(false);

  // Modify the toggleDropdown function to handle mobile dropdown
  const toggleDropdown = (isMobile) => {
    if (isMobile) {
      // Toggle mobile dropdown visibility
      setShowMobileDropdown((prevState) => !prevState);
    } else {
      // Toggle desktop dropdown visibility
      setShowDropdown((prevState) => !prevState);
    }
  };

  const handleItemClick = () => {
    setShowDropdown(false);
  };

  const handleMobileItemClick = () => {
    setShowMobileDropdown(false);
    setMobile(false);
  };

  const handletreat = (department) => async (e) => {
    e.preventDefault();

    try {
      const res = await ServiceCall.gett(
        `/rxone/fetch/all/treatment/departments/${department}`
      );
      if (res) {
        setDepartdata(res?.data?.departments || []);
      }
    } catch (err) {
      console.error("Error fetching department data:", err);
      setDepartdata([]);
    }
  };

  const isSpecialBackground = nav || isMobile;

  return (
    <>
      <header
        className={` header ${nav && "header__color"} ${
          isMobile && "header__color"
        } ${islogin && "login__container"}  ms-main-header`}
      >
        {islogin && <Login />}
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav className="navbar navbar-expand-lg px-0">
                <Link className="navbar-brand" to="/">
                  <img
                    src={isSpecialBackground ? header__logo : header__logo1}
                    alt="Logo"
                  />
                </Link>
                {/* /*===== Navbar===== */}
                <div
                  className="collapse navbar-collapse justify-content-end"
                  id="hamburgernavmenucontent"
                >
                  <ul className="navbar-nav align-items-lg-center">
                    <li
                      className="nav-item ms-line-slide"
                      style={{
                        display: "inline-block", // Prevent the li from wrapping
                        marginRight: "15px", // Optional: Adds space between items
                      }}
                    >
                      <a
                        href="https://rxonecare.com/"
                        className="nav-link"
                        style={{
                          color: nav || isMobile ? "#000" : "#fff", // White if the background is #018c92, otherwise black
                          whiteSpace: "nowrap", // Prevents the text from breaking to the next line
                          textDecoration: "none", // Optional: removes underline from the link
                        }}
                      >
                        For Providers
                      </a>
                    </li>

                    <li
                      className="nav-item ms-line-slide"
                      style={{
                        display: "inline-block", // Prevent the li from wrapping
                        marginRight: "15px", // Adds space between items (optional)
                      }}
                    >
                      <NavLink
                        activeclassname="active"
                        to="/international-patients"
                        style={{
                          color: nav || isMobile ? "#000" : "#fff", // White if the background is #018c92, otherwise black
                          whiteSpace: "nowrap", // Prevents the text from wrapping to the next line
                          textDecoration: "none", // Optional: removes underline from the link
                        }}
                      >
                        International Patients
                      </NavLink>
                    </li>

                    <li style={{ marginLeft: "1rem" }}>
                      <div
                        className="dropdown-menu-v2"
                        onMouseEnter={() => setShowDropdown(true)}
                        onMouseLeave={() => setShowDropdown(false)}
                        style={{ position: "relative" }}
                      >
                        <div
                          className="dropdown-toggle"
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            marginRight: "1.3rem",
                            cursor: "pointer",
                            color: nav || isMobile ? "#000" : "#fff",
                          }}
                        >
                          Services
                        </div>
                        {showDropdown && (
                          <ul
                            className="dropdown-list"
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: 0,
                              backgroundColor: "#fff",
                              listStyle: "none",
                              padding: "10px 0",
                              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                              borderRadius: "8px",
                              zIndex: 100,
                            }}
                          >
                            <li
                              className="dropdown-item"
                              style={{ padding: "10px 20px" }}
                              onClick={handleItemClick}
                            >
                              <NavLink
                                to="/specialists/MODE"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}
                                activeStyle={{ color: "#007bff" }}
                              >
                                Consultation
                              </NavLink>
                            </li>
                            <li
                              className="dropdown-item"
                              style={{ padding: "10px 20px" }}
                              onClick={handleItemClick}
                            >
                              <NavLink
                                to="/treatment-advice"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}
                                activeStyle={{ color: "#007bff" }}
                              >
                                Treatment Advice
                              </NavLink>
                            </li>
                            <li
                              className="dropdown-item"
                              style={{ padding: "10px 20px" }}
                              onClick={handleItemClick}
                            >
                              <NavLink
                                to="/specialists/MODE"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}
                                activeStyle={{ color: "#007bff" }}
                              >
                                Second Opinion
                              </NavLink>
                            </li>
                            <li
                              className="dropdown-item"
                              style={{ padding: "10px 20px" }}
                              onClick={handleItemClick}
                            >
                              <NavLink
                                to="/order-medicine-online-india"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}
                                activeStyle={{ color: "#007bff" }}
                              >
                                Order Medicine
                              </NavLink>
                            </li>
                            <li
                              className="dropdown-item"
                              style={{ padding: "10px 20px" }}
                              onClick={handleItemClick}
                            >
                              <NavLink
                                to="/book-pathology-tests-india"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}
                                activeStyle={{ color: "#007bff" }}
                              >
                                Order Test
                              </NavLink>
                            </li>
                            <li
                              className="dropdown-item"
                              style={{ padding: "10px 20px" }}
                              onClick={handleItemClick}
                            >
                              <NavLink
                                to="/medical-finance-loan-india"
                                style={{
                                  textDecoration: "none",
                                  color: "#000",
                                }}
                                activeStyle={{ color: "#007bff" }}
                              >
                                Medical Loan
                              </NavLink>
                            </li>
                          </ul>
                        )}
                      </div>
                    </li>
                    {/* <li>
                      <div
                        className="dropdown-menu-v2"
                        onMouseEnter={() => setShowDropdown2(true)}
                        onMouseLeave={() => {
                          if (showProceduresDropdown) {
                            setShowDropdown2(false);
                            setSelectedDepartment(null);
                            setShowProceduresDropdown(false);
                          }
                        }}
                        style={{ position: "relative" }}
                      >
                        <div
                          className="dropdown-toggle"
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            marginRight: "1.2rem",
                            marginLeft: "15px",
                            cursor: "pointer",
                            color: nav || isMobile ? "#000" : "#fff",
                          }}
                        >
                          Treatments
                        </div>

                        {showDropdown2 && (
                          <div
                            style={{
                              position: "fixed",
                              top: "60px", 
                              left: "50%",
                              transform: "translateX(-50%)",
                              zIndex: 1000,
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                              maxWidth: "1200px",
                              padding: "0 20px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                position: "relative",
                                margin: "0 auto",
                              }}
                            >
                              
                              <ul
                                className="dropdown-list"
                                style={{
                                  backgroundColor: "#fff",
                                  listStyle: "none",
                                  padding: "10px 0",
                                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                                  borderRadius: "8px",
                                  maxHeight: "400px",
                                  overflowY: "auto",
                                  minWidth: "250px",
                                  position: "relative",
                                }}
                              >
                                {treatdata.map((department, index) => (
                                  <li
                                    key={index}
                                    className="dropdown-item"
                                    style={{
                                      padding: "10px 20px",
                                      backgroundColor:
                                        selectedDepartment === department
                                          ? "#f0f0f0"
                                          : "transparent",
                                      cursor: "pointer",
                                    }}
                                    onMouseEnter={() => {
                                      setSelectedDepartment(department);
                                      handletreat(department)({
                                        preventDefault: () => {},
                                      });
                                      setShowProceduresDropdown(true);
                                    }}
                                  >
                                    <span
                                      style={{
                                        textDecoration: "none",
                                        color: "#000",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      {department}
                                      <ArrowRightIcon
                                        style={{ fontSize: 20 }}
                                      />
                                    </span>
                                  </li>
                                ))}
                              </ul>

                             
                              {showProceduresDropdown &&
                                departdata &&
                                departdata.length > 0 && (
                                  <ul
                                    className="dropdown-list"
                                    style={{
                                      backgroundColor: "#fff",
                                      listStyle: "none",
                                      padding: "10px 0",
                                      boxShadow:
                                        "0px 8px 16px rgba(0, 0, 0, 0.1)",
                                      borderRadius: "8px",
                                      maxHeight: "400px",
                                      overflowY: "auto",
                                      minWidth: "300px",
                                      marginLeft: "10px",
                                      position: "relative",
                                    }}
                                    onMouseLeave={() => {
                                      setShowProceduresDropdown(false);
                                      setShowDropdown2(true);
                                      setSelectedDepartment(null);
                                    }}
                                  >
                                    {departdata.map((proc, index) => (
                                      <li
                                        key={index}
                                        className="dropdown-item"
                                        style={{ padding: "10px 20px" }}
                                      >
                                        <NavLink
                                          to={"/procedure/${proc.route_key}"}
                                          style={{
                                            textDecoration: "none",
                                            color: "#000",
                                            display: "block",
                                          }}
                                          onClick={() => {
                                            setShowProceduresDropdown(false);
                                            setShowDropdown2(false);
                                            setSelectedDepartment(null);
                                          }}
                                        >
                                          {proc.procedure}
                                        </NavLink>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    </li> */}
                    <li className="nav-item ms-line-slide">
                      <NavLink
                        activeclassname="active"
                        to="/treatment"
                        style={{
                          color: nav || isMobile ? "#000" : "#fff", // White if the background is #018c92, otherwise black
                        }}
                      >
                        Treatments
                      </NavLink>
                    </li>
                    <li
                      className="nav-item ms-line-slide"
                      style={{
                        display: "inline-block", // Prevent the li from wrapping
                        marginRight: "15px", // Optional: Adds space between items
                      }}
                    >
                      <NavLink
                        activeclassname="active"
                        to="/aboutus"
                        style={{
                          color: nav || isMobile ? "#000" : "#fff", // White if the background is #018c92, otherwise black
                          whiteSpace: "nowrap", // Prevents the text from breaking to the next line
                          textDecoration: "none", // Optional: removes underline from the link
                        }}
                      >
                        About Us
                      </NavLink>
                    </li>

                    <li className="nav-item ms-line-slide">
                      <NavLink
                        activeclassname="active"
                        to="/blogs"
                        style={{
                          color: nav || isMobile ? "#000" : "#fff", // White if the background is #018c92, otherwise black
                        }}
                      >
                        Blog
                      </NavLink>
                    </li>
                    {/* <li className="nav-item ms-line-slide">
                      <NavLink activeclassname="active" to="/providers">
                        For Providers
                      </NavLink>
                    </li> */}
                    <li className="nav-item">
                      <a
                        className="ms-secondary-btn ms-header-btn"
                        href="http://www.tosto.re/RxOne"
                        style={{
                          display: "inline-block",
                          padding:
                            "12px 40px" /* Controls the height and width of the button */,
                          fontSize: "16px",
                          fontWeight: "500",
                          backgroundColor: "var(--ms-primary-color)",
                          color: "white",
                          borderRadius:
                            "30px" /* This keeps the button rounded */,
                          textAlign: "center",
                          textDecoration: "none",
                          lineHeight:
                            "1.5" /* Adjusts text vertical alignment */,
                          whiteSpace:
                            "nowrap" /* Prevents text from wrapping */,
                          transition: "background-color 0.3s ease-in-out",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor =
                            "var(--ms-primary-hover-color)";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor =
                            "var(--ms-primary-color)";
                        }}
                      >
                        Download App
                      </a>
                    </li>
                  </ul>
                </div>
                {/* /*========= Mobile Version ========= */}
                <div
                  className={`menu__toggle__content ${
                    !isMobile && "res__display"
                  }`}
                  style={{ marginLeft: "-8rem", marginTop: "3rem" }}
                >
                  <ul>
                    <li
                      style={{
                        listStylePosition: "outside",
                        paddingLeft: "0",
                        marginLeft: "0px",
                      }}
                    >
                      <a
                        href="https://rxonecare.com/"
                        className="nav-link"
                        style={{
                          display: "inline-block",
                          paddingLeft: "0",
                          marginLeft: "2.5rem",
                        }}
                      >
                        For Providers
                      </a>
                    </li>

                    <li onClick={handleMobileItemClick}>
                      <NavLink
                        activeclassname="active"
                        to="/international-patients"
                      >
                        International Patients
                      </NavLink>
                    </li>
                    <li>
                      <div
                        onClick={() => toggleDropdown(true)}
                        className="dropdown-toggle"
                        style={{
                          fontSize: "17px",
                          fontWeight: "600",
                          marginRight: "20px",
                          marginLeft: "18px",
                          cursor: "pointer",
                        }}
                      >
                        Services
                      </div>
                      {showMobileDropdown && (
                        <ul className="mobile-dropdown-list">
                          <li
                            className="mobile-dropdown-item"
                            onClick={handleMobileItemClick}
                          >
                            <NavLink to="/specialists/MODE">
                              Consultation
                            </NavLink>
                          </li>
                          <li
                            className="mobile-dropdown-item"
                            onClick={handleMobileItemClick}
                          >
                            <NavLink to="/treatment-advice">
                              Treatment Advice
                            </NavLink>
                          </li>
                          <li
                            className="mobile-dropdown-item"
                            onClick={handleMobileItemClick}
                          >
                            <NavLink to="/specialists/MODE">
                              Second Opinion
                            </NavLink>
                          </li>
                          <li
                            className="mobile-dropdown-item"
                            onClick={handleMobileItemClick}
                          >
                            <NavLink to="/order-medicine-online-india">
                              Order Medicine
                            </NavLink>
                          </li>
                          <li
                            className="mobile-dropdown-item"
                            onClick={handleMobileItemClick}
                          >
                            <NavLink to="/book-pathology-tests-india">
                              Order Test
                            </NavLink>
                          </li>
                          <li
                            className="mobile-dropdown-item"
                            onClick={handleMobileItemClick}
                          >
                            <NavLink to="/medical-finance-loan-india">
                              Medical Loan
                            </NavLink>
                          </li>
                        </ul>
                      )}
                    </li>
                    <li onClick={handleMobileItemClick}>
                      <NavLink activeclassname="active" to="/treatment">
                        Treatments
                      </NavLink>
                    </li>
                    <li onClick={handleMobileItemClick}>
                      <NavLink activeclassname="active" to="/aboutus">
                        About Us
                      </NavLink>
                    </li>
                    <li
                      onClick={handleMobileItemClick}
                      style={{ marginBottom: "10px" }}
                    >
                      <NavLink activeclassname="active" to="/blogs">
                        Blog
                      </NavLink>
                    </li>
                    <li>
                      <a
                        style={{
                          padding: "5px 30px",
                          fontSize: "14px",
                          display: "inline-block",
                          marginLeft: "7px",
                        }}
                        className="ms-secondary-btn ms-header-btn"
                        href="http://www.tosto.re/RxOne"
                      >
                        {swap}
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="menu_toggle"
                  onClick={() => setMobile(!isMobile)}
                >
                  {isMobile ? (
                    <CloseIcon fontSize="medium" />
                  ) : (
                    <MenuIcon fontSize="medium" />
                  )}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
