import React,{useState, useRef, useEffect} from 'react'
import ServiceCall from "../ServiceCall"
import axios from "axios"
import {
  Row,
  Container,
  Col,
  Form
} from "react-bootstrap"
import {Button, Modal} from "react-bootstrap"
  import { ControlPointTwoTone, Create, MobileFriendlyOutlined } from "@material-ui/icons";

//   const defaultError = {
//   phoneNumber: {
//     hasError: false,
//     errorMsg: "",
//   },
//   remember: {
//     hasError: true,
//     errorMsg: "",
//   },
// };

//   const defaultFormData = {
//     phoneNumber: "",
//     remember: true,
//   };




function PatientDetails({doctorDetails,bookAppointment,token,hospitalDetail,patientDetail,otpPatientLoader}) {

  const[phoneNo, setPhoneNo] = useState("")
  const[ptToken, setPtToken] = useState("");
  const[loader, showLoader] = useState(false)
  const[updateLoader, setUpdateLoader] = useState(false)
  const[resendLoader, setResendLoader] = useState(false)
  const[emptyError, setEmptyError] = useState(false)
  const[showError, setShowError] = useState(false)
  const[isVerify, setVerify] = useState(false)
  const [counter, setCounter] = useState()
  const [showOtp, setShowOtp] = useState(false);
  const [bookFunction, setbookFunction] = useState(false)
  const [hospitalData, setHospitalData] = useState("");
  const [otpContent, setOtpContent] = useState("");
  const id = React.useRef(null);
  const timer = useRef(null);
  const [phoneCode, setPhoneCode] = useState("+91");
  const profile = { email: "",
  name: "",
  gender:"",
  age:"",
  address: "",
  city: "",
  country:"",
  weight_kg:"",
  height_cm:"",
  blood_type:""
}
  const [updateProfile, setUpdateProfile] = useState(profile)

  // <----- Modal window starts from here ----------- > 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

useEffect(() => {
  if (showOtp) {
       if(counter > 0) {
        let timerID =  setInterval(() => setCounter((time)=> time-1), 1000);
        if(counter == 1){
         setCounter();
        }
         return () => clearInterval(timerID)
       }
    }

  },[counter])

  useEffect(()=>{ 
    if(bookFunction){
      bookAppointment("online")
      return () => {
        setbookFunction()
      }
    }
  },[bookFunction])


  const updateForm = (e) => {
    let number = e.target.value;
    setPhoneNo(number)
    return() => number
    
  };
  
  
  const getOtp = async (e) => {
    e.preventDefault()
    // getOtpLoader(true);
    // setDisableOtp(true);
    
    let regex =/^[6-9]\d{9}$/;

     if(phoneNo == ""){
      setEmptyError(true)
      document.getElementById("exampleInputEmail1").style.border = "1px solid red"
     }

     if(regex.test(phoneNo)){
      setShowError(false)
      setEmptyError(false)
      document.getElementById("exampleInputEmail1").style.border = "1px solid rgb(13,110,253)";

      AfterOtp();
     
      
    }else{
      setShowError(true)
      setEmptyError(false)
      document.getElementById("exampleInputEmail1").style.border = "1px solid red"
    }
  };
  
  const AfterOtp = async () =>{
   
    showLoader(true)
    try {
    const response =   await ServiceCall.post(
      `/patient/otp`,
      {
        "phone": phoneCode + phoneNo,
        "hos_id": doctorDetails.hos_id
      }
    )
     if(response.status = 200){
       showLoader(false)
       setShowOtp(true)
       setCounter(60)
     }
    } catch(error){
      console.log(error)
      showLoader(false)
    }

   }
   
   const handleResendOTP= async(e)=>{
     e.preventDefault();
     setResendLoader(true)
     try {
       const response =   await ServiceCall.post(
         `/patient/otp`,
         {
           "phone": phoneCode + phoneNo,
           "hos_id": doctorDetails.hos_id
          }
          )
          if(response.status = 200){
            setShowOtp(true)
         setCounter(60)
         setResendLoader(false)
       }
      } catch(error){
       setShowOtp(false)
      }

   }

   const handleOTP = (e) =>{
     let otp = e.target.value;
     
    let otpregex = /^[0-9]{6}$/;
    
    if(otpregex.test(otp)){
      setVerify(true)
    }else{
      setVerify(false)
    }
  }
  
const handleVerifyOTP = async(e) =>{
  e.preventDefault();
  showLoader(true)
  setVerify(false)
  try {
    const response =   await ServiceCall.post(
      `/patient/login`,
      {
        "phone": phoneCode + phoneNo,
        otp: otpContent,
        "hos_id": doctorDetails.hos_id
      }
    )
     if(response){
       let pt__token = response.data.pt_token;
       token(pt__token)
       hospitalDetails();
       patientDetails(pt__token)
       document.getElementById("exampleInputPassword1").style.border ="1px solid rgb(13,110,253)"
     }
    } catch(error){
       document.getElementById("exampleInputPassword1").style.border ="1px solid red"
      showLoader(false)
    }

}




async function hospitalDetails(){
    
    try{
        
        const response = await ServiceCall.get(`/hospital/details/${doctorDetails.hos_id}`)

    if(response.status == 200){
      setHospitalData(response.data)
      hospitalDetail(hospitalData)
      //   if(sendHospitalData === true){
    //   }
}

}catch(error){
    
    setShowOtp(false)
    
}

}

async function patientDetails(item){
  
  try{
    
    const response = await ServiceCall.get('/patient/profile',{
      "pt_token" :item
    })
    
    if(response.status == 200){
    //   console.log(ptToken)
     if(response.data.patient_profile != null){
       patientDetail(response.data)
       setbookFunction(true)
     }else{
      setPtToken(item)
      handleShow()
      showLoader(false)
      setVerify(true)
      setUpdateLoader(false)
     }
    }

   }catch(error){
    
    setShowOtp(false)

   }
 }

 const handleUpdateProfile = async(e) =>{
    e.preventDefault()
    console.log(ptToken)
    setUpdateLoader(true)
    let regexName =/^[a-zA-Z]/g
    let regexEmail = /([a-zA-Z0-9]+)([\.{1}])?([a-zA-Z0-9]+)\@gmail([\.])com/g;
   let regexAge = /^[1-9]?[0-9]{1}$|^100$/

    if(updateProfile.name== "" || !regexName.test(updateProfile.name)){
      document.getElementById("updateName").style.border = "1px solid red"
      setUpdateLoader(false)
    }else if(updateProfile.email==""|| !regexEmail.test(updateProfile.email)){
      document.getElementById("updateEmail").style.border = "1px solid red"
      document.getElementById("updateName").style.border ="1px solid rgb(13,110,253)"
      setUpdateLoader(false)
    }else if(updateProfile.age == "" || !regexAge.test(updateProfile.age)){
      document.getElementById("updateAge").style.border = "1px solid red"
      document.getElementById("updateEmail").style.border ="1px solid rgb(13,110,253)"
      setUpdateLoader(false)
    }else{
      document.getElementById("updateAge").style.border ="1px solid rgb(13,110,253)"
      document.getElementById("updateName").style.border ="1px solid rgb(13,110,253)"
      document.getElementById("updateEmail").style.border ="1px solid rgb(13,110,253)"

      
      
      try {
        const response = await ServiceCall.put('/patient/profile',{
          email:updateProfile.email,
          name:updateProfile.name,
          gender: updateProfile.gender,
          age: updateProfile.age,
          address: updateProfile.address,
          city:updateProfile.city,
          country:updateProfile.country,
          weight_kg: updateProfile.weight_kg,
          height_cm:updateProfile.height_cm,
          blood_type:updateProfile.blood_type
        },
        { pt_token: ptToken }
        )
  
        if(response){
          patientDetails(ptToken)
          setVerify(false)
          handleClose()
        }
  
      }catch(err){
        console.log(err)
        setUpdateLoader(false)
      }
    }
 }
 
//  ***********************     calling bookAppointmentCall Function      ******************************** 



  return (
       <>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header >
          <p className='text-center'>It seems you are booking appointment with us for the first time. Please provide following details to continue booking.</p>
        </Modal.Header>
        <Modal.Body>
          <form>
          <div class="mb-3">
            <input type="text" className="form-control rounded-0 p-2 fs-6" id="updateName" placeholder='Full Names' aria-describedby="emailHelp" required onChange={(e)=> {setUpdateProfile({...updateProfile, name:e.target.value})}}/>
            <input type="email" className="form-control rounded-0 p-2 mt-2 fs-6" id="updateEmail" placeholder='Email' aria-describedby="emailHelp" required onChange={(e)=>{setUpdateProfile({...updateProfile,email : e.target.value})}}/>
            <div className='row'>
              <div className="col">
              <input type="number" className="form-control rounded-0 p-2 mt-2 fs-6" id="updateAge" placeholder='AGE' aria-describedby="emailHelp" required onChange={(e)=> {setUpdateProfile({...updateProfile, age:e.target.value})}}/>
              </div>
              <div className="col">
              <select className="form-select mt-2 p-2" required aria-label="Default select example" onChange={(e)=>{setUpdateProfile({...updateProfile,gender: e.target.value})}}>
                  <option selected>Gender</option>
                  <option value="Male">male</option>
                  <option value="Female">female</option>
              </select>
              </div>
            </div>
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close 
          </Button>
          <Button variant="danger" onClick={(e)=>{handleUpdateProfile(e)}}>
            Save Changes {updateLoader && <span className="spinner-border spinner-border-sm ms-2" role="status" ></span>}
          </Button>
        </Modal.Footer>
      </Modal>
   


   {showOtp ? 
   
   <form>
   <label  className="form-label">Please Enter the OTP send on your NO.</label>
    <input type="text" onChange={(e)=>{setOtpContent(e.target.value); handleOTP(e) } } className="rounded-2 p-2 fs-6 form-control" id="exampleInputPassword1"/>
    <div className=" d-flex mt-3 justify-content-between">
    <button onClick={handleResendOTP}  type="submit" className={` btn btn-danger ${counter>0 && "disabled"} ` }>Resend OTP <span>{counter}</span>{resendLoader && <span className="spinner-border spinner-border-sm ms-2" role="status" ></span> } </button>
    <button onClick={handleVerifyOTP} type="submit" className={`btn btn-danger ${!isVerify && "disabled"} `}>Verify {loader && <span className="spinner-border spinner-border-sm ms-2" role="status" ></span>}</button>
    </div>
   </form>
   
   :
   
    <div>
    <form >
    <div className="mb-3">
    <label style={{fontSize:"18px"}} className="form-label fw-normal  mt-2">Enter your Phone no. to receive OTP </label>
    <div className="d-flex">
     <div className='d-flex align-items-center border border-1 px-2'>+91</div><input type="tel" className="form-control rounded-0 p-2 fs-5" id="exampleInputEmail1" placeholder='Number' aria-describedby="emailHelp "  onChange={(e)=> updateForm(e)}  ></input>
    </div>
    {emptyError && <label style={{color:"#D32F2F", fontSize:"12px" }} >Please Enter Number</label> }
    {showError && <label style={{color:"#D32F2F", fontSize:"12px" }} >Please Enter Correct Number</label> }
    </div>
    <button  onClick={getOtp} type="submit" className="btn btn-danger" >Request OTP {loader && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>}</button>
    </form>
    </div>
   }
   </>
  )
}

export default PatientDetails