import React, { useState, useEffect } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import "../../Css/login.css"
// import patient from "../../Images/login/patient-svgrepo-com.svg"
import doctor from "../../Images/login/doctor-svgrepo-com.svg"
import provider from "../../Images/login/provider-fst-svgrepo-com.svg"
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Portal from './Portal';
import ServiceCall from "../../ServiceCall"
import axios from 'axios';


function Login({popup, login}) {

  const[portal, setportal] = useState(false)
  const[docportal, setdocportal] = useState(false)
  const [data, setData] = useState([])
  

/********* DOCTOR AND PATIENT URL  ********* */
 let doctorPortalUrl = ServiceCall.docProtalUrl
 let  patientPortalUrl = ServiceCall.patientProtalUrl
/********* DOCTOR AND PATIENT URL  ********* */

useEffect(()=>{
  if(login){
    document.body.style.overflow = "hidden"
  }
})

// const firstApi = async () => {
//   const result = await ServiceCall.get("/rxonecare/hospitals")
//   setData(result.data.hospitals)

// }




  return (
    <>
    {portal && <Portal close={setportal} background = {"iframe__container__patient"} url={patientPortalUrl}   hospitalData={data}/>}
    {docportal && <Portal url={doctorPortalUrl} background={"iframe__container__doctor"}  close={setdocportal}  hospitalData={data}/>}
    <div className={`${portal && "d-none"} login_modal`} >
   <div className='login_modal_content'>
   <CancelIcon  className="login__Cancel " fontSize='large' onClick={()=>popup(false)} />
   <h5 className='login__heading'>LOGIN AS</h5>
   <div className="login__btns d-flex justify-content-center align-items-center flex-column">
   {/* <button className='d-flex justify-content-evenly  ms-secondary-btn'  onClick={()=>firstApi(setportal(true))}  > <img src={patient} alt="patient"/> <span className='login__btn'>Patient</span> <DoubleArrowIcon style={{}} fontSize="large"/></button> */}
   <button className='d-flex justify-content-evenly  ms-secondary-btn' onClick={()=>window.open("https://www.doctor.rxone.app/")}> <img  src={doctor} alt="doctor"/> <span className='login__btn'>Doctor</span> <DoubleArrowIcon style={{}} fontSize="large"/></button>    
   <button className='d-flex justify-content-evenly ms-secondary-btn' onClick={()=>{window.open("https://www.provider.rxone.app/user/login", "_blank")}}><img src={provider} alt="provider"/> <span className='login__btn'>Provider</span> <DoubleArrowIcon style={{}} fontSize="large"/></button>
   </div>
   </div>
  </div>
  </>
  );
}

export default Login;