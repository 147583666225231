import React, { useState, useEffect } from "react";
import ServiceCall from "../ServiceCall";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import "../Css/Cureways.css";
import backgroundimg from "../Images/banner-bg.jpg";
import { Carousel } from "react-bootstrap";
// import drkamal from "../Images/johny.jpg";
import drKamal from "../Images/johny.png";
import image__2 from "../Images/about/core-values-word-young-people.jpg";

const Cureways = () => {
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const location = useLocation();
  const { code } = useParams();
  const [cured, setCured] = useState(null);
  const mode = process.env.REACT_APP_MODE;
  const navigate = useNavigate();

  const [details, setDetails] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ServiceCall.gett(
          `/rxone/web/fetch/cw/details/${code}`
        );
        if (response && response.data) {
          setCured(response.data);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    fetchData();
  }, [code]);

  useEffect(() => {
    const doctorpro = async () => {
      try {
        const doctorspro = await ServiceCall.gett(
          `/v2/patient/open/list/available/doctor/${mode}?som=${code}`
        );
        if (doctorspro) {
          setDetails(doctorspro.data.doctors); // Assign the correct array to details
        }
      } catch (err) {}
    };
    doctorpro();
  }, [code]);

  const doctorpro = (res) => {
    const profileUrl = `/profile/${res.route_key}`; // Include route_key in the URL

    // Navigate to the profile page with state
    navigate(profileUrl);
    // console.log("speciality", res)
  };
  const handleregis = () => {
    navigate("/treatment-advice");
  };

  const unicode = "\u20B9";

  return (
    <>
      <div className="cureways">
        <img
          src={backgroundimg}
          style={{
            width: "100%",
            height: "300px",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1, // To place it behind other content
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          alt="Background"
        />
        <span
          style={{
            display: "block",
            width: "100vw", // Use 100vw for full viewport width
            backgroundColor: "rgb(0 92 116)",
            color: "#ffffff",
            padding: "6px",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "21px",
            marginTop: "90px",
            position: "relative", // Add position relative
            left: "50%", // Move the element 50% to the left
            right: "50%", // Move the element 50% to the right
            marginLeft: "-50vw", // Compensate the left movement
            marginRight: "-50vw", // Compensate the right movement
            marginBottom: "-104px",
          }}
        >
          LIMITED TIME OFFER! Get{" "}
          <span style={{ color: "rgb(255, 203, 37)" }}>FREE</span> treatment
          advice from our experts
          <button className="handlrs" onClick={handleregis}>
            <span style={{ fontSize: "17px" }}>CONSULT NOW</span>
          </button>
        </span>
        <div className="wrapper" style={{ display: "flex" }}>
          {cured && (
            <div className="curecards" style={{ flex: 1 }}>
              <div className="row align-items-center margin__second g-5">
                <div
                  data-aos="slide-right"
                  data-aos-duration="500"
                  className="col-lg-6 d-flex mb-3" // Add ml-5 class here for left margin
                >
                  {/* <img style={{width:"500px"}} src={image__2}  className="img-fluid ms-border-rounded" alt="Image"/> */}
                  <Carousel controls={false} interval={1000} indicators={false}>
                    {cured.details.images_urls.map((imageUrl, index) => (
                      <Carousel.Item key={index}>
                        <img
                          src={imageUrl}
                          alt={`Slide ${index + 1}`}
                          style={{ width: "100%", height: "300px" }} // Set a fixed height for all images
                          className="img-fluid ms-border-rounded" // Assuming this class handles border-radius
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
                <div data-aos="slide-left" className="col-lg-6">
                  <h3 className="ms-font-weight-600 mb-4 ms-primary-color">
                    {cured.title}
                  </h3>
                  <div className="d-flex mb-3">
                    {/* <i className="fas fa-check-circle ms-check-icon"></i> */}
                    <p className="ms-2 mb-0 fw-normal"> {cured.description}</p>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row align-items-center mb-5 g-5 flex-column flex-lg-row">
                  <div data-aos="slide-right" className="col-lg-6">
                    <h3 className="ms-font-weight-600 mb-4 ms-primary-color">
                      Pros
                    </h3>
                    <div className="d-flex mb-3">
                      <ul>
                        {cured.details.pros.map((pro, index) => (
                          <div className="d-flex mb-3" key={index}>
                            <i className="fas fa-check-circle ms-check-icon"></i>
                            <h6 className="ms-2 mb-0 fw-normal">{pro}</h6>
                          </div>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div data-aos="slide-left" className="col-lg-6">
                    <h3 className="ms-font-weight-600 mb-4 ms-primary-color">
                      Cons
                    </h3>
                    <div className="d-flex mb-3">
                      <ul>
                        {cured.details.cons.map((con, index) => (
                          <div className="d-flex mb-3" key={index}>
                            <i className="fas fa-check-circle ms-check-icon"></i>
                            <h6 className="ms-2 mb-0 fw-normal">{con}</h6>
                          </div>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="content-container">
            <div className="doctors-column">
              {details.length === 0 ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ width: "100%" }}
                >
                  <div
                    className="spinner-border d-flex align-items-center justify-content-center"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                details.map((res, key) => (
                  <div
                    className="ms-doctors"
                    key={key}
                    style={{
                      cursor: "pointer",
                      maxWidth: "198px",
                      marginBottom: "20px",
                    }}
                    onClick={() => doctorpro(res)}
                  >
                    <div className="row">
                      <div className="col-lg-12 pe-0">
                        <div className="row mb-3">
                          <div className="col-sm-12 col-md-auto">
                            <img
                              src={ServiceCall.images(
                                `v2/patient/doctor/pic/${mode}/${res.doc_id}`
                              )}
                              className="img-fluid ms-doc-img"
                              alt="Image"
                              onClick={() => doctorpro(res)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                          <div
                            className="col-sm-12 col-md"
                            style={{ textAlign: "left" }}
                          >
                            <h1
                              className="ms-font-weight-600 mb-1"
                              style={{ fontSize: "20px" }}
                            >
                              {res.doctor_name}
                            </h1>
                            <h6
                              className="mb-0 text-break"
                              style={{ fontWeight: "600" }}
                            >
                              {res.speciality}
                            </h6>
                            <p className="mb-0">
                              {res.experience} Years Experience
                            </p>
                            {res.consult_charges !== 0 && (
                              <p
                                className="mb-4"
                                style={{
                                  fontWeight: "600",
                                  fontSize: "23px",
                                  color: "black",
                                  marginRight: "107px",
                                }}
                              >
                                {unicode}
                                {res.consult_charges}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Cureways;
