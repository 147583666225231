import React, { useEffect, useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import "../Css/providers.css";
import AOS from "aos";
import "aos/dist/aos.css";
import emailjs from "emailjs-com";
import Appointment from "../Images/provider/care_provider_1.gif";
import Medicine from "../Images/provider/experience_1.gif";
import Lab from "../Images/provider/zero_cost_1.gif";
import providerIcon1 from "../Images/icons/rx one icons (4).svg";
import providerIcon2 from "../Images/icons/rx one icons (5).svg";
import providerIcon3 from "../Images/icons/rx one icons (6).svg";
import bannerImg1 from "../Images/provider/man-girl-doctor-are-smiling-together.png";
import bannerImg2 from "../Images/provider/banner-image-01.png";
import bannerImg3 from "../Images/provider/banner-image.png";
import bannerImg4 from "../Images/provider/bg-img.png";
import NukaCarousel from "../tools/NukaCarousel";
import experience from "../Images/provider/our-experience.png";
import telemedicine from "../Images/provider/beautiful-young-asian-girl-doctor-with-laptop.png";
import { revolutionize } from "./config";
import analytics from "../Images/provider/mobile-mockup.jpg";
// import MyVerticallyCenteredModal from "react-bootstrap"
import ProviderModal from "./ProviderModal";
import { Carousel } from "react-bootstrap";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import picture1 from "../Images/Picture1.png";
import picture2 from "../Images/Picture2.png";
import picture3 from "../Images/Picture3.png";
import picture4 from "../Images/Picture4.png";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


function Providers() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  });
  const headings = [
    "Every Provider, needs One - RxOne",
    "Many Facilities or One, Manage with RxOne",
    "Scale Care Services Faster",
    "Improve Patient Outcomes",
  ];

  const bannerImages = [bannerImg1, bannerImg2, bannerImg3, bannerImg4];
  const [isStuck, setIsStuck] = useState(false);

  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  async function sendMail(e) {
    e.preventDefault();
    setLoader(true);
    let data = await emailjs.sendForm(
      "service_g4xmqpq",
      "template_ftbb6zk",
      e.target,
      "user_nG8O481HPP4hLDs3wFbUi"
    );

    if (data.status == 200) {
      setModalShow(true);
      setLoader(false);
      setAlert(true);
    }
  }

  useEffect(() => {
    if (alert) {
      setTimeout(function () {
        setAlert(false);
      }, 3000);
    }
  });
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  const handleRemoveVideo = () => {
    const videoWrapper = document.getElementById("video-wrapper");
    if (videoWrapper) {
      videoWrapper.remove();
      // setIsStuck(false);
    }
  };

  // src="https://www.youtube.com/embed/eKs2tjZw_08?si=5jndS8W63kPS7t7N&autoplay=1&loop"

  return (
    <>
      <div>
        <div id="video-wrapper" className="video-wrap stuck">
          <div className="video">
            <iframe
              title="RxOne Demo Video"
              width="240"
              height="135"
              style={{
                borderRadius: "10px",
                boxShadow: "0 6px 14px rgba(5, 8, 7, 3.1)",
              }}
              src="https://www.youtube.com/embed/eKs2tjZw_08?si=5jndS8W63kPS7t7N&autoplay=1&loop"
              frameBorder="0"
              gesture="media"
              allowFullScreen
            ></iframe>
            {/* Cross button */}
            <button
              className="cross-button"
              onClick={handleRemoveVideo}
              style={{
                position: "absolute",
                top: "-18px",
                right: "-7px",
                zIndex: 999,
                background: "none",
                border: "none",
                cursor: "pointer",
                // color: "black",
                fontSize: "20px",
                fontWeight: "600",
              }}
              onMouseEnter={(e) => e.target.style.color = "#005c74"} 
              onMouseLeave={(e) => e.target.color = "#fff"}

            >
              <CancelOutlinedIcon style={{  color: "#fff", cursor: "pointer"}}/>
            </button>
          </div>
        </div>

        {/* {!isStuck && (
       <button onClick={() => setIsStuck(true)}>Show Video</button>
     )} */}
      </div>

      {/* <!-- Banner Section --> */}
      <section style={{ gap: "0px" }} className="ms-providers-banner">
        <div className="container provider__containers">
          <div data-aos="fade-up" className="row align-items-center">
            <div className=" provider__container col-xl-9 col-xxl-7 mt-5">
            <h1 className="fw-bold mb-3 mt-5">
                      {headings[activeIndex]}
                    </h1>
              <a
                href="#."
                className="ms-primary-btn"
                onClick={() => {
                  window.open(
                    "https://www.provider.rxone.app/user/sign-up",
                    "_self"
                  );
                }}
              >
                Signup Now
                <ChevronRightIcon />
              </a>
            </div>
          </div>
        </div>
        <div className="provider__swiper__box">
          <Carousel
            activeIndex={activeIndex}
            onSelect={handleSelect}
            controls={false}
            interval="4000"
            indicators={false}
            className="provider__swiper"
          >
            {bannerImages.map((bannerImg, index) => (
              <Carousel.Item
                key={index}
                className="provider__swiper__container"
              >
                <img src={bannerImg} alt={`Slide ${index + 1}`} />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </section>

      {/* ------products---- */}
      <section data-aos-duration="3000" className="ms-cureways-section">
        <div data-aos="fade-up" className="container">
          <div className="row mb-5">
            <div className="col-md-12 text-center">
              <h2 className="ms-font-weight-600 ms-primary-color">Products </h2>
            </div>
          </div>
          <div className="row g-4 align-items-center">
            <div data-aos="slide-up" className=" flip__card col-md-4 ">
              <div className="flip__card__inner ms-icon-box">
                <div className=" flip__card__front">
                  <img src={picture1} className="img-fluid" alt="Image" />
                  <h4 style={{ fontWeight: "550" }} className="mt-4 mb-0">
                    EMR
                  </h4>
                </div>
                <div className="flip__card__back">
                  <p>
                    Next-Gen, AI powered EMR – Dynamic OPD Management,
                    Self-Check In, Autonomous Patient Engagement, Records
                    Summarization, BYOD (Bring-your-own-device) IoT
                    connectivity, Custom Integrations
                  </p>
                </div>
              </div>
            </div>
            <div className=" flip__card col-md-4 ">
              <div className="flip__card__inner ms-icon-box">
                <div className=" flip__card__front">
                  <img src={picture2} className="img-fluid" alt="Image" />
                  <h4 style={{ fontWeight: "550" }} className="mt-4 mb-0">
                    Market360°
                  </h4>
                </div>
                <div className="flip__card__back">
                  <p>
                    One service covering both On-Page & Off-Page Marketing –
                    Branded, Self-service Web App, Dynamic SEO, Content
                    Curation, Social/Digital Marketing, Advertisements
                  </p>
                </div>
              </div>
            </div>
            <div data-aos="slide-up" className=" flip__card col-md-4 ">
              <div className="flip__card__inner ms-icon-box">
                <div className=" flip__card__front">
                  <img src={picture3} className="img-fluid" alt="Image" />
                  <h4 style={{ fontWeight: "550" }} className="mt-4 mb-0">
                    UHX
                  </h4>
                </div>
                <div className="flip__card__back">
                  <p>
                    UHX (Unified Health Exchange) – ABHA Integration
                    (as-service), Data Locker, Data Exchange and Digital Health
                    Incentive Scheme (DHIS)
                  </p>
                </div>
              </div>
            </div>

            <div data-aos="slide-up" className=" flip__card col-md-4 ">
              <div className="flip__card__inner ms-icon-box">
                <div className=" flip__card__front">
                  <img src={picture4} className="img-fluid" alt="Image" />
                  <h4 style={{ fontWeight: "550" }} className="mt-4 mb-0">
                    EDGE
                  </h4>
                </div>
                <div className="flip__card__back">
                  <p>
                    Healthcare IoT – Medical Equipment/Devices Integration,
                    Remote Patient Monitoring, Medical Cold Storage
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* *------ Services Section --------*  */}

      <section className="ms-apart-section">
        <div data-aos="slide-up" className="container">
          <div className="row mb-5">
            <div className="col-md-12 text-center">
              <h2
                data-aos="fade-up"
                className="ms-font-weight-600 ms-primary-color"
              >
                Scale your Services like never before !
              </h2>
            </div>
          </div>
          <div className="row g-4 align-items-center">
            <div data-aos="slide-up" className=" flip__card col-md-4 ">
              <div className="flip__card__inner ms-icon-box">
                <div className=" flip__card__front">
                  <img src={Appointment} className="img-fluid" alt="Image" />
                  <h5 style={{ fontWeight: "550" }} className="mt-4 mb-0">
                    Highly suitable for Care Providers
                  </h5>
                </div>
                <div className="flip__card__back">
                  <p>
                    RxOne Platform is uniquely crafted to meet the needs on
                    today’s Care business. Whether you are running individual
                    clinic, have small scale hospital or running
                    multi-speciality care service. Our platform can excel your
                    care pathways like never before.
                  </p>
                </div>
              </div>
            </div>
            <div className=" flip__card col-md-4 ">
              <div className="flip__card__inner ms-icon-box">
                <div className=" flip__card__front">
                  <img src={Medicine} className="img-fluid" alt="Image" />
                  <h5 style={{ fontWeight: "550" }} className="mt-4 mb-0">
                    Personalize experience for your Patients
                  </h5>
                </div>
                <div className="flip__card__back">
                  <p>
                    You want to provide best care services to your patients.
                    That’s why RxOne’s custom interface for your brand will help
                    you provide exceptional care services, while directly
                    engaging your patients.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div data-aos="slide-up" className=" flip__card col-md-4 ">
              <div className="flip__card__inner ms-icon-box">
                <div className=" flip__card__front">
                  <img src={Lab} className="img-fluid" alt="Image" />
                  <h5 style={{ fontWeight: "550" }} className="mt-4 mb-0">
                    Zero Cost, No-Install Apps
                  </h5>
                </div>
                <div className="flip__card__back">
                  <p>
                    Yes this is True! Our apps are free for your usage forever.
                    Best part is, no need to install another app for Patient,
                    Doctor or Provider. Our apps works on any platform, any web
                    browser without installations.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Revolutionizing Healthcare section  */}

      <section
        style={{
          background: "linear-gradient(120deg, rgb(237, 233, 233), #edf2f7)",
        }}
        className="ms-apart-section"
      >
        <div data-aos="slide-up" className="container">
          <div className="row mb-5">
            <div className="col-md-12 text-center">
              <h2
                data-aos="fade-up"
                className="ms-font-weight-600 ms-primary-color"
              >
                Revolutionizing Healthcare with a Digital native approach
              </h2>
            </div>
          </div>
          <div className="row g-4 provider__revolutionize_container align-items-center">
            {!!revolutionize?.length ? (
              <NukaCarousel
                nextDisabled={true}
                previousDisabled={true}
                renderCenterLeftControls={false}
                renderCenterRightControls={false}
                carouselClass="provider__revolutionize__carousel"
                slidesToShow={3}
                slidesToshowOnMobile={1}
                props={{
                  cellSpacing: 10,
                  autoplay: true,
                  autoplayInterval: 2000,
                  wrapAround: true,
                }}
              >
                {revolutionize?.map((item, index) => (
                  <div className="provider__revolutionize__item" key={index}>
                    <div className="provider__revolutionize__icon">
                      <img src={item?.icon} alt={item?.alt} />
                    </div>
                    <h4 className="provider__revolutionize__title text-center ms-font-weight-600 mt-3 mb-4 ms-primary-color">
                      {item?.title}
                    </h4>
                    <h6 className="provider__revolutionize__text ms-2 mb-0 fw-normal text-center">
                      {item?.text}
                    </h6>
                  </div>
                ))}
              </NukaCarousel>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      {/* <!-- Content Section --> */}
      <section className="ms-content-section">
        <div className="container">
          <div className="row align-items-center mb-5 g-5 flex-column-reverse flex-lg-row">
            <div data-aos="slide-right" className="col-lg-6">
              <div className="content__logo">
                <img src={providerIcon1} alt="content-logo" />
              </div>
              <h3 className="ms-font-weight-600 mb-4 ms-primary-color">
                One Experience
              </h3>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  Personalize Patient & Doctor Portals for your Brand
                </h6>
              </div>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  Single Interface, whether you manage one facility (location)
                  or multiple
                </h6>
              </div>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  Start with One Service (OPD) or Multiple Services (OPD,
                  Pharmacy, Labs etc.) under your Brand
                </h6>
              </div>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  Highly user friendly portal, Test Mode for Staff Training
                </h6>
              </div>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  Marketing Campaigns to funnel growth
                </h6>
              </div>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  Easy Pay-Collect-Settle cycles
                </h6>
              </div>
            </div>
            <div data-aos="slide-left" className="col-lg-6">
              <img
                src={experience}
                className="img-fluid ms-border-rounded"
                alt="Image"
              />
            </div>
          </div>
          <div
            style={{ padding: "50px 0px" }}
            className="row align-items-center g-5"
          >
            <div data-aos="slide-right" className="col-lg-6">
              <img
                src={telemedicine}
                className="img-fluid ms-border-rounded"
                alt="Image"
              />
            </div>
            <div data-aos="slide-left" className="col-lg-6">
              <div className="content__logo">
                <img src={providerIcon2} alt="content-logo" />
              </div>
              <h3 className="ms-font-weight-600 mb-4 ms-primary-color">
                Telemedicine Simplified
              </h3>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  An easy to use interface for Doctors to manage Calendar, view
                  Patient Records, past prescriptions and attend Video
                  appointments
                </h6>
              </div>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  Patient 360* view during consultation for efficient prognosis
                  and quality diagnosis
                </h6>
              </div>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  Doctor view for appointments and collection trend
                </h6>
              </div>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  Manage follow-ups and track patient condition
                </h6>
              </div>
            </div>
          </div>
          <div className="row align-items-center mb-5 g-5 flex-column-reverse flex-lg-row">
            <div data-aos="slide-right" className="col-lg-6">
              <div className="content__logo">
                <img src={providerIcon3} alt="content-logo" />
              </div>
              <h3 className="ms-font-weight-600 mb-4 ms-primary-color">
                Actionable Insights
              </h3>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  Operational Analytics, including daily appointments and
                  collection
                </h6>
              </div>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  Patient Bio-Markers Trend for Doctors for better diagnosis
                </h6>
              </div>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  Automated Health Records Management and Integration with
                  National Ayushman Bharat Mission - Digital Health ID
                </h6>
              </div>
              <div className="d-flex mb-3">
                <i className="fas fa-check-circle ms-check-icon"></i>
                <h6 className="ms-2 mb-0 fw-normal">
                  Marketing Campaign and Patient engagement analytics
                </h6>
              </div>
            </div>
            <div
              style={{ maxWidth: "550px", marginTop: "100px" }}
              data-aos="slide-left"
              className="col-lg-6"
            >
              <img
                src={analytics}
                className="img-fluid ms-border-rounded"
                alt="Image"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Request Demo Section --> */}
      <section class="ms-request-demo-section">
        <div class="container">
          <div
            style={{ gap: "20px" }}
            className="d-flex justify-content-center align-items-center  flex-column"
          >
            <h2
              data-aos="fade-up"
              class="ms-font-weight-600 text-center mb-2 ms-primary-color"
            >
              Let Us Contact You For A Demo
            </h2>
            <p
              data-aos="fade-up"
              style={{ width: "40%" }}
              class="fw-normal text-center ms-third-color mb-4"
            >
              Leave your phone number here, and our team will reach out to you
              to schedule a demo with you.
            </p>
            <form
              onSubmit={(e) => sendMail(e)}
              data-aos="fade-up"
              class="position-relative ms-form-layout"
            >
              <input
                name="phone"
                type="tel"
                class="form-control"
                placeholder="Phone"
                required
              />
              <button type="submit" class="ms-secondary-btn mt-3 mt-md-0">
                Request Demo
                {loader && (
                  <span
                    class="spinner-border spinner-border-sm ms-2"
                    role="status"
                  ></span>
                )}
              </button>
            </form>
            <ProviderModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Providers;
