import { useState, useEffect, useRef, useCallback } from "react";
import backgroundimg from "../Images/banner-bg.jpg";
import Calenderimg from "../Images/provider/treatment_img.png";
import emailjs from "emailjs-com";
import { countriesList } from "../utility/constants";
import { styled, useMediaQuery } from "@material-ui/core";
import { Helmet } from "react-helmet";
import CenteredAlert from "./CenteredAlert";
import CircularProgress from "@material-ui/core/CircularProgress";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import whatsapp from "../Images/whatsapp.png";
// import { revolutionizes } from "./config";
import { revolutionizes } from "./config";
import NukaCarousel from "../tools/NukaCarousel";
import { faqs } from "./config";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ReCAPTCHA from "react-google-recaptcha";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    // margin: theme.spacing(2, 0),
    backgroundColor: "#008b91",
    marginTop: "2rem",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#02585c",
    },
  },
  arrowIcon: {
    position: "absolute",
    top: "70px",
    left: theme.spacing(2),
    cursor: "pointer",
  },
  nextButton: {
    backgroundColor: "#008b91",
    marginTop: "2rem",
    color: "#fff",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#02585c",
    },
    "&:disabled": {
      backgroundColor: "#ccc",
      color: "#666",
    },
  },
  recaptcha: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  header: {
    fontSize: "41px",
    marginTop: (props) =>
      props.isMobile ? "69px" : props.step === 1 ? "-293px" : "-362px",

    color: "#fff",
    fontWeight: 600,
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
}));

const Patientregis = () => {
  const [loader, setLoader] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [countryname, setCountryname] = useState("India");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const[know, setKnow] = useState("");
  const [country, setCountry] = useState("India");
  const [city, setCity] = useState("");
  const isMobile = useMediaQuery("(max-width:710px)");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false); // State for managing loading state
  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const [step, setStep] = useState(1);
  const [isStep1Valid, setIsStep1Valid] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [preferredHospital, setPreferredHospital] = useState('');
const [preferredLocation, setPreferredLocation] = useState('');
  const classes = useStyles({ step, isMobile });

  const isValidPhoneNumber = (phoneNumber) => {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
    return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
  };
  const showAlerts = () => {
    setShowAlert(true);
    resetForm();
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setAge("");
    setGender("");
    setKnow("");
    setPhone("");
    setCountry("");
    setCity("");
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA");
      return;
    }
  
    setLoading(true);
    const serviceid = "service_7yxtqsn";
    const templateid = "template_x3wusd7";
    const publickey = "4UpyJJj3P5JGeMZFz";
    const messageContent = `
    Patient Name: ${name}
    Age: ${age}
    Gender: ${gender}
    Phone Number: ${phoneCode}${phone}
    Email: ${email}
    Country: ${country}
    City: ${city}
    Chief Complaints: ${message}
  `;

    const templateparams = {
      from_name: name,
      from_email: email,
      to_name: "web",
      message: messageContent,
    };

    emailjs
      .send(serviceid, templateid, templateparams, publickey)
      .then((response) => {
        setLoading(false);
        console.log("email set successfully", response);
        showAlerts();
      })
      .catch((error) => {
        setLoading(false);
        console.log("error sending email", error);
      });
  };

  const handlePhoneCodeChange = (event) => {
    setPhoneCode(event.target.value);
  };
  const handleRecaptchaChange = useCallback((value) => {
    setRecaptchaValue(value);
  }, []);

  const toggleQuestion = (id) => {
    if (expandedQuestions.includes(id)) {
      setExpandedQuestions(expandedQuestions.filter((qid) => qid !== id));
    } else {
      setExpandedQuestions([...expandedQuestions, id]);
    }
  };

  useEffect(() => {
    const isValid =
      name.trim() !== "" &&
      age.trim() !== "" &&
      gender !== "" &&
      message.trim() !== "";
    setIsStep1Valid(isValid);
  }, [name, age, gender, message]);

  const titlle =
    "FREE Treatment Advice - Consult for Medical Treatment Advice - Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, Mental Health | RxOne";
  const desc =
    "Get free treatment advice and consult for medical treatments including Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, and Mental Health at RxOne.";

  const handleBack = () => {
    setStep(1);
  };
  const handleNext = () => {
    if (isStep1Valid) {
      setStep(2);
    }
  };

  const renderStep1 = () => (
    <>
      <TextField
        id="firstName"
        name="first_name"
        label="Patient Name"
        variant="filled"
        style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
        autoFocus
        fullWidth
        className={classes.nameField}
        InputProps={{
          style: {
            backgroundColor: "#fff", // Set the background color here
          },
          inputProps: {
            style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
          },
        }}
      />
      <TextField
        id="age"
        name="age"
        label="Patient Age"
        variant="filled"
        value={age}
        onChange={(e) => setAge(e.target.value)}
        style={{ marginBottom: "20px", backgroundColor: "white" }}
        fullWidth
        className={classes.nameField}
        onKeyPress={(e) => {
          // Allow only digits (0-9)
          const allowedChars = /^[0-9]*$/;
          if (!allowedChars.test(e.key)) {
            e.preventDefault();
          }
        }}
        inputProps={{
          maxLength: 3, // Optional: Limits the maximum length to 3 digits
        }}
        InputProps={{
          style: {
            backgroundColor: "#fff",
            marginTop: "7px",
            marginBottom: "-3px", // Set the background color here
          },
        }}
      />
      <FormControl
        variant="filled"
        style={{ marginBottom: "20px" }}
        fullWidth
        className={classes.nameField}
        classes={{ root: classes.root }} // Apply the custom styles
      >
        <InputLabel id="gender">Gender</InputLabel>
        <Select
          labelId="gender"
          id="gender"
          name="gender"
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          classes={{ root: classes.root }} // Apply the custom styles
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
      </FormControl>

      <TextField
        multiline
        maxRows={4}
        id="chiefComplaints"
        name="message"
        label="Describe current medical problem"
        variant="filled"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
        fullWidth
        style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
        className={classes.nameField}
        InputProps={{
          style: {
            backgroundColor: "#fff", // Set the background color here
          },
          inputProps: {
            style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
          },
        }}
      />

<TextField
      label="Preferred Hospital (Optional)"
      value={preferredHospital}
      onChange={(e) => setPreferredHospital(e.target.value)}
      fullWidth
      style={{ marginBottom: "20px", backgroundColor: "white" }}
      className={classes.nameField}
      InputProps={{
        // style: {
        //   backgroundColor: "#fff",
        // },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" },
        },
      }}
    />
    
    <TextField
      label="Preferred Treatment Location (Optional)"
      value={preferredLocation}
      onChange={(e) => setPreferredLocation(e.target.value)}
      fullWidth
      style={{ marginBottom: "20px",  }}
      className={classes.nameField}
      InputProps={{
        // style: {
        //   backgroundColor: "#fff",
        // },
        inputProps: {
          style: { marginTop: "7px", marginBottom: "-3px" },
        },
      }}
    />
      <Button
        className={classes.nextButton}
        variant="contained"
        onClick={handleNext}
        fullWidth
        disabled={!isStep1Valid}
      >
        Next
      </Button>
    </>
  );

  
  const renderStep2 = () => (
    <>
      <FormControl variant="filled" fullWidth className={classes.nameField}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="w-23"
            value={phoneCode} // Concatenate phoneCode and phone here
            onChange={handlePhoneCodeChange}
            classes={{ root: classes.root }}
            style={{ marginTop: "16px" }}
          >
            {countriesList &&
              countriesList.map((item, index) => (
                <MenuItem
                  value={item.code}
                  key={item.code + "-" + index}
                  classes={{ root: classes.menuItem }} // Apply the custom styles to the MenuItem
                >
                  {item.code}
                </MenuItem>
              ))}
          </Select>

          <InputLabel htmlFor="phoneNumber" style={{ marginLeft: "101px" }}>
            Phone Number*
          </InputLabel>
          <Input
            id="phoneNumber"
            name="phone"
            label="Phone Number"
            required
            value={phone}
            onChange={(e) => {
              const value = e.target.value;
              const allowedChars = /^[0-9]*$/; // Allow only digits

              if (allowedChars.test(value)) {
                setPhone(value.slice(0, 10)); // Limit the input to 10 digits
                setPhoneError(false); // Reset the error state
              } else {
                setPhoneError(true); // Set the error state if non-digit characters are entered
              }
            }}
            error={phoneError}
            style={{ flex: 1, marginLeft: 6, width: "100px" }}
            className={classes.nameField}
            InputProps={{
              style: {
                backgroundColor: "#fff",
              },
            }}
          />
        </div>
        {phoneError && (
          <FormHelperText error>
            Please enter a valid phone number.
          </FormHelperText>
        )}
      </FormControl>
      <TextField
        id="email"
        name="email"
        label="Email"
        variant="filled"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        required
        style={{ marginBottom: "20px", backgroundColor: "white" }}
        className={classes.nameField}
        inputProps={{
          pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
          title: "Please enter a valid email address",
        }}
        InputProps={{
          style: {
            backgroundColor: "#fff", // Set the background color here
          },
          inputProps: {
            style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
          },
        }}
      />
      <FormControl
        id="country"
        name="country"
        variant="filled"
        required
        fullWidth
        style={{ marginBottom: "20px", backgroundColor: "white" }}
        className={classes.nameField}
      >
        <InputLabel id="gender">Select your country</InputLabel>
        <Select
          labelId="country"
          id="country"
          className="w-23"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          classes={{ root: classes.root }}
          InputProps={{
            style: { backgroundColor: "#fff" },
            inputProps: { style: { maxWidth: "100px" } },
          }}
          MenuProps={{
            components: {
              MenuItem: (props) => (
                <MenuItem
                  {...props}
                  classes={{ root: classes.menuItem }}
                  style={{ maxWidth: "100px" }}
                >
                  {props.children}
                </MenuItem>
              ),
            },
          }}
        >
          {countriesList &&
            countriesList.map((item, index) => (
              <MenuItem value={item.name} key={`${item.name}-${index}`}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl
        variant="filled"
        style={{ marginBottom: "20px" }}
        fullWidth
        className={classes.nameField}
        classes={{ root: classes.root }} // Apply the custom styles
      >
        <InputLabel id="know">How Did you know about this</InputLabel>
        <Select
          labelId="know"
          id="know" 
          name="know"
          value={know}
          onChange={(e) => setKnow(e.target.value)}
          classes={{ root: classes.root }} // Apply the custom styles
        >
          <MenuItem value="google search">Google Search</MenuItem>
          <MenuItem value="facebook">Facebook</MenuItem>
          <MenuItem value="friends/relatives">Friends/Relatives</MenuItem>
          <MenuItem value="past patients">Suggested by past patients</MenuItem>
          <MenuItem value="ambassador">Ambassador</MenuItem>
          <MenuItem value="other">Others</MenuItem>


        </Select>
      </FormControl>
      <TextField
        id="city"
        name="city"
        label="Enter your city"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        variant="filled"
        required
        fullWidth
        style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
        className={classes.nameField}
        InputProps={{
          style: {
            backgroundColor: "#fff", // Set the background color here
          },
          inputProps: {
            style: { marginTop: "7px", marginBottom: "-3px" }, // Add padding to the left side of the input text
          },
        }}
      />
      <span
        style={{
          fontSize: "11px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        *We value your privacy, your details will be kept confidential. Refer
        our <a href="https://rxone.app/privacypolicy">Privacy Policy</a>
      </span>
      <div className={classes.recaptcha}>
        <ReCAPTCHA
          sitekey="6LddplcqAAAAACztSGsmgN9xckbNg_5mn4p4t3A5"
          onChange={handleRecaptchaChange}
        />
      </div>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        disabled={loading || !recaptchaValue}
        >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          "Request Callback"
        )}
      </Button>
    </>
  );

  return (
    <>
      <Helmet>{<title>{titlle}</title>}</Helmet>
      <Helmet>{<meta name="description" content={desc} />}</Helmet>

      <section data-aos-duration="3000" className="ms-cureways-section">
        <span
          style={{
            padding: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row", // Change flex direction based on screen size
            marginTop: isMobile ? "1rem" : "0",
            marginTop: "4rem",
            marginBottom: "-7.5rem",
          }}
        />
        <div
          style={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: isMobile ? "column" : "row", // Change flex direction based on screen size
            marginTop: isMobile ? "-77px" : "0",
          }}
        >
          <div
            style={{
              maxWidth: isMobile ? "100%" : "45%", // Set maxWidth to 100% for mobile
              // textAlign: "center", // Center align text on mobile
              marginTop: isMobile ? "0" : "355px", // Remove negative margin on mobile
            }}
          >
            <h1 className={classes.header}>
              Talk to Medical Expert for{" "}
              <span style={{ color: "#fff" }}>FREE</span>
            </h1>
            <ul className="mb-2">
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Find answers to your medical questions
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Find the best doctor and hospital
              </li>
              <li
                className="ms-font-weight-600 mb-4 ms-primary-color"
                style={{ color: "#fff", fontWeight: "bold", fontSize: "21px" }}
              >
                Find the right cost of your treatment
              </li>
            </ul>

            <p style={{ fontWeight: "bold", color: "#fff", fontSize: "21px" }}>
              And get assistance for everything in between
            </p>

            <img src={Calenderimg} style={{textAlign: "center", marginTop: "2rem",  height: "26rem", width: "27rem", borderRadius: "31px", marginLeft: isMobile ? "-1.3rem" : ""}} />

          </div>
          <div className="whatsapp-block">
            <a
              href="https://api.whatsapp.com/send?phone=+917703934446&text=Hello%20RxOne,%20Could%20you%20please%20call%20back."
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsapp} alt="whtsp" />
            </a>{" "}
          </div>
          <div style={{ float: "inline-end", marginTop: "4rem" }}>
            <Container component="main" maxWidth="xs">
              <div
                className="provider__revolutionize__item"
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  borderRadius: "55px",
                  boxShadow: "rgba(0, 0, 0, 0.5) 0px 8px 16px",
                  position: "relative", // Add this to position the arrow icon
                }}
              >
                {step === 2 && (
                  <ArrowBackIosNewIcon
                    className={classes.arrowIcon}
                    onClick={handleBack}
                  />
                )}
                <h4
                  className="ms-font-weight-600 mb-4 ms-primary-color"
                  style={{ textAlign: "center" }}
                >
                  Get call from Care Advisor
                </h4>
                <form
                  style={{ textAlign: "center", width: "123%" }}
                  onSubmit={handlesubmit}
                >
                  {step === 1 ? renderStep1() : renderStep2()}
                </form>
              </div>
            </Container>
          </div>
        </div>
      </section>
      {showAlert && (
        <CenteredAlert
          message="We have received your request, please allow us up-to 24 Hrs. to come back to you."
          onClose={() => setShowAlert(false)}
        />
      )}
      <section
        style={{
          background: "linear-gradient(120deg, rgb(237, 233, 233), #edf2f7)",
        }}
        className="ms-apart-section"
      >
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12 text-center">
              <h2
                // data-aos="fade-up"
                className="ms-font-weight-600 ms-primary-color"
              >
                How it works?
              </h2>
            </div>
          </div>
          <div className="row g-4 provider__revolutionize_container align-items-center">
            {!!revolutionizes?.length ? (
              <NukaCarousel
                nextDisabled={true}
                previousDisabled={true}
                renderCenterLeftControls={false}
                renderCenterRightControls={false}
                carouselClass="provider__revolutionize__carousel"
                slidesToShow={3}
                slidesToshowOnMobile={1}
                props={{
                  cellSpacing: 10,
                  autoplay: true,
                  autoplayInterval: 1000,
                  wrapAround: true,
                }}
              >
                {revolutionizes?.map((item, index) => (
                  <div className="provider__revolutionize__item" key={index}>
                    <div className="provider__revolutionize__icon">
                      <img src={item?.icon} alt={item?.alt} />
                    </div>
                    <h4 className="provider__revolutionize__title text-center ms-font-weight-600 mt-3 mb-4 ms-primary-color">
                      {item?.title}
                    </h4>
                  </div>
                ))}
              </NukaCarousel>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>

      <h5 className="mb-1" style={{ marginLeft: "1rem", fontSize: "1.4rem" }}>
        Frequently Asked Questions
      </h5>

      <ul style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
        {faqs.map((faq) => (
          <li className="languages card" key={faq.id}>
            <div>
              <strong>{faq.question}</strong>
              <button
                onClick={() => toggleQuestion(faq.id)}
                style={{
                  border: "none",
                  background: "none",
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  marginTop: "-22px",
                }}
              >
                <span>{expandedQuestions.includes(faq.id) ? "▲" : "▼"}</span>
              </button>
            </div>
            {expandedQuestions.includes(faq.id) && <p>{faq.answer}</p>}
          </li>
        ))}
      </ul>
    </>
  );
};
export default Patientregis;
