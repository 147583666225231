import React, { useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import "../Css/treatmentmini.css";
import CircularProgress from "@material-ui/core/CircularProgress";

// Import images
import img7 from "../Images/home/hipreplacement.png";
import img8 from "../Images/home/orthopedics.png";
import img9 from "../Images/home/liver1.png";
import img10 from "../Images/home/urology.png";
import img11 from "../Images/home/ivf (1).png";
import img12 from "../Images/home/transplant.png"; 

// Array of items with six treatment options
const miniItems = [
  { id: 7, image: img7, name: "HIP REPLACEMENT", routekey: "hip-replacement" },
  { id: 8, image: img8, name: "KNEE REPLACEMENT", routekey: "knee-replacement" },
  { id: 9, image: img9, name: "LIVER TRANSPLANT", routekey: "liver-transplant" },
  { id: 10, image: img10, name: "KIDNEY TRANSPLANT", routekey: "kidney-transplant" },
  { id: 11, image: img11, name: "IVF", routekey: "ivf" },
  { id: 12, image: img12, name: "BONE MARROW TRANSPLANT", routekey: "bone-marrow-transplant" },
];

const Treatmentmini = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // To manage the loading state
  const [loadingItem, setLoadingItem] = useState(null); // To track which item is loading

  const handleNav = (routeKey) => {
    setLoading(true); // Start loading
    setLoadingItem(routeKey); // Track the clicked item
    
    // Simulate an API call or navigation
    setTimeout(() => {
      navigate(`/treatments/${routeKey}`, {
        state: { routekey: routeKey },
      });
      setLoading(false); // Set loading to false after a delay (i.e., data is loaded)
      setLoadingItem(null); // Reset the loading item after data is loaded
    }, 1500); // Simulating a delay of 1.5 seconds (replace with actual API call)
  };

  const Loader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px", // Adjust size as needed
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress />
    </div>
  );

  return (
    <section className="treatment-mini">
      <div className="heading-description1">
        <h2>Top Treatments</h2>
        <p>Click More to discover various treatments offered by our partner NABH & JCI accredited Hospitals in India & Globe.</p>
      </div>
      <div className="mini-rectangle-container">
        {miniItems.map((item) => (
          <div
            key={item.id}
            className="mini-rectangle"
            onClick={() => handleNav(item.routekey)}
            style={{
              position: "relative", // Make sure the loader is positioned correctly inside each item
              cursor: "pointer",
            }}
          >
            {/* Show loader if the clicked item is loading */}
            {loading && loadingItem === item.routekey ? (
              <Loader /> // Display loader inside the clicked item
            ) : (
              <>
                <div className="plus-icon">
                  <AddIcon />
                </div>
                <div className="logo">
                  <img src={item.image} alt={`Logo of ${item.name}`} />
                </div>
                <div className="name">{item.name}</div>
              </>
            )}
          </div>
        ))}
      </div>
      <NavLink
        to="/treatment"
        className="ms-secondary-btn ms-header-btn"
        style={{ marginTop: "30px", marginBottom: "20px" }}
      >
        More
      </NavLink>
    </section>
  );
};

export default Treatmentmini;