import React, { useContext } from 'react';
import { Grid, Button, Box, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import { green, red, blue } from '@mui/material/colors';
// import { PatientpageContext } from "./../context/GlobalContext";
import ServiceCall from '../ServiceCall';
// import ImageAvatar from """;
import { baseUrl, constants } from "../utility/constants";
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import { CircularProgress } from "@material-ui/core";
import dayjs from 'dayjs';
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

const CheckoutScreen = ({ selectedDoctor, classes, paymentType, appointmentDetails, patientProfile, bookAppointmentCall, appointmentType,nextStep, bookAppLoader,inPerson }) => {
    // const patientContext = useContext(PatientpageContext);
    // const [total, setTotal] = useState(0);
    const paymentScreen = function () {
            return <><VideoCameraFrontIcon fontSize="small" color="action" /> <span className='pl-2 ms-2 me-1'>Online ({inPerson=="False"? "Tele" : "Clinic"}) appointment</span></>
    }
    const findTotal = function () {
        if (paymentType === 'online') {
            return parseFloat(selectedDoctor.consult_charges) + parseFloat(selectedDoctor.online_discount_amount);
        } 
    }
    function startPayment() {
        // patientContext.handleDialogClose();
        // bookAppointmentCall(paymentType);
        nextStep(oldValue => oldValue+1)
    }

    // console.log(selectedDoctor)
    return (
        <div>
            <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={12} className='checkoutAppointmentType d-flex justify-content-start align-items-center font-13 pl-4'>
                  <p> {paymentScreen()}  on <span className='font-weight-bold ms-1'> {dayjs(appointmentDetails.date).format("Do MMM YYYY")}, {appointmentDetails.time}</span>
                    </p>  

                </Grid>
                <Grid item xs={12} className='appointmentDetails d-flex justify-content-start align-items-center font-13 pt-1'>
                    {/* on <span className='font-weight-bold ms-1'> {dayjs(appointmentDetails.date).format("Do MMM YYYY")}, {appointmentDetails.time}</span> */}
                </Grid>
                <Grid item xs={12} className="text-center">
                    <Box sx={{ padding: '9px 9px', border: `2px solid ${blue['700']}`, marginBottom: 3 }}>
                        <h4>Bill Details</h4>
                        <TableContainer>
                            <Table aria-label="simple table" border={0}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="border-0" border={0}>Consultation Fee</TableCell>
                                        <TableCell className="border-0">Rs. {selectedDoctor.consult_charges}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="border-0">Booking Fee</TableCell>
                                        <TableCell className="border-0"><span style={{ textDecoration: 'line-through' }}>Rs.50</span> <span className='font-weight-bold' style={{ color: green["700"] }}>FREE</span></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ borderBottomColor: "#212121" }}>Online Payment Discount</TableCell>
                                        {paymentType === 'online' && <TableCell style={{ borderBottomColor: "#212121" }}>Rs. <span style={{ color: red["700"] }}>({selectedDoctor.online_discount_amount})</span></TableCell>}
                                        {/* {paymentType === 'cash' && <TableCell style={{ borderBottomColor: "#212121" }}>Rs. 0</TableCell>} */}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="font-weight-bold border-0">Total Payable</TableCell>
                                        <TableCell className="font-weight-bold border-0">Rs. {findTotal()}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    {/* <Box className="text-left p-2 font-12" sx={{ border: 1, borderColor: '#dddddd' }}>Appointment for:  <span className="font-weight-bold">{patientProfile.patient_profile.name}</span></Box> */}
                </Grid>
                <Grid container item className='pt-4 text-center' xs={12}>
                    <Grid item xs={6} className="fs-5 fw-bolder text-left" style={{ color: blue["600"] }}>Rs. {findTotal()}</Grid>
                    <Grid item xs={6} className='text-right'>
                        <Button
                            onClick={startPayment}
                            variant='contained'
                            disabled={bookAppLoader}
                            color='error'>Next
                            {bookAppLoader && <CircularProgress size={14} />}
                        </Button></Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default CheckoutScreen;
