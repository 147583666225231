import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'; 

const HospitalPath = ({path,headerfooter}) => {

    const location = useLocation();
    console.log("location", location)
    useEffect(()=>{
      headerfooter(false)
      if(path){
        redirect()
      }else if(typeof window !=="undefined"){
        window.location.href="/"
      }
  
    },[path])
  
    const redirect = () =>{
      let redirect = false;
      const basePath = "/doctor/"
      if(path && path.length !==0){
         path.map((list)=>{
           if(`${basePath}${list.short_name}` === location.pathname){
             if(typeof window !=="undefined"){
                redirect = true; 
                window.location.href=list.doctor_portal_url
             }
           }
         })
    
         if(!redirect && typeof window !=="undefined"){
           window.location.href="/"
         }
      }
    }
  
    return (
      <div style={{height:"40vh", marginTop:"100px"}} class="d-flex justify-content-center align-items-center flex-column">
        <div className="spinner-border "   role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
      </div>  
    )
  }

export default HospitalPath