import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import logo from "./Images/SVG/header logo.svg"


const ErrorPage = ({path,headerfooter}) => {
  const location = useLocation();


  useEffect(()=>{
    headerfooter(false)
    if(path){
      redirect()
    }else if(typeof window !=="undefined"){
      window.location.href="/"
    }

  },[path])

  const redirect = () =>{
    let redirect = false;
    const basePath = "/"
    if(path && path.length !==0){
       path.map((list)=>{
         if(`${basePath}${list.short_name}` === location.pathname){
           if(typeof window !=="undefined"){
              redirect = true; 
              window.location.href=list.care_portal_url
           }
         }
       })
  
       if(!redirect && typeof window !=="undefined"){
         window.location.href="/"
       }
    }
  }

  return (
    <div style={{height:"40vh", marginTop:"100px"}} class="d-flex justify-content-center align-items-center flex-column">
      <div className="spinner-border "   role="status">
          <span className="visually-hidden">Loading...</span>
      </div>
    </div>  
  )
}

export default ErrorPage