import { useState, useEffect, useRef } from "react";
import backgroundimg from "../Images/banner-bg.jpg";
import emailjs from "emailjs-com";
import { countriesList } from "../utility/constants";
import { useMediaQuery } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { medicals } from "./config";
import NukaCarousel from "../tools/NukaCarousel";
import { faqs } from "./config";
import { medi } from "./config";
import { faqsss } from "./config";
import docgirl from "../Images/home/medical-loan-clipart.png";
import {
  TextField,
  Button,
  Container,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  MenuItem,
  FormHelperText,
  Select,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(4),
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(2, 0),
    backgroundColor: "#199a8e",
    "&:hover": {
      // Remove hover effect
      backgroundColor: "#199a8e",
    },
  },
  salutationFormControl: {
    width: "27%", // Adjust the width of the salutation select
  },
  gender: {
    width: "30%",
  },
  nameContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  nameField: {
    background: "white",
  },
  select: {
    border: "none", // Remove border
    width: "50px", // Adjust the width as needed
  },
}));

const Medicalloan = () => {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [pin, setPin] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const isMobile = useMediaQuery("(max-width:710px)");
  const isValidPhoneNumber = (phoneNumber) => {
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
    return numericPhoneNumber.length === 10 && /^\d+$/.test(numericPhoneNumber);
  };
  const [expandedQuestions, setExpandedQuestions] = useState([]);

  const handlesubmit = (e) => {
    e.preventDefault();
    const serviceid = "service_7yxtqsn";
    const templateid = "template_x3wusd7";
    const publickey = "4UpyJJj3P5JGeMZFz";
    const messageContent = `
    Type: 'Medical Loan Query'
    Name: ${name}
    Number: ${phoneCode}${phone}
    City: ${city}
    Address: ${message}
    Pin: ${pin}
  `;

    const templateparams = {
      from_name: name,
      from_email: email,
      to_name: "web",
      message: messageContent, // Include the messageContent here
    };

    emailjs
      .send(serviceid, templateid, templateparams, publickey)
      .then((response) => {
        console.log("email set successfully", response);
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        console.log("error sending email", error);
      });
  };

  const toggleQuestion = (id) => {
    if (expandedQuestions.includes(id)) {
      setExpandedQuestions(expandedQuestions.filter((qid) => qid !== id));
    } else {
      setExpandedQuestions([...expandedQuestions, id]);
    }
  };

  const titlle =
    "FREE Treatment Advice - Consult for Medicalloan Treatment Advice - Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, Mental Health | RxOne";
  const desc =
    "Get free treatment advice and consult for medical treatments including Allopathy, Homeopathy, Ayurveda, Unani, Sowa-Rigpa, Siddha, Yoga, Reki, Naturopathy, Meditation, Physiotherapy, and Mental Health at RxOne.";
  return (
    <>
      <Helmet>{<title>{titlle}</title>}</Helmet>
      <Helmet>{<meta name="description" content={desc} />}</Helmet>

      <section data-aos-duration="3000" className="ms-cureways-section">
        <div
          style={{
            padding: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row", // Change flex direction based on screen size
            marginTop: isMobile ? "1rem" : "0",
            marginTop: "4rem",
            marginBottom: "-6rem",
          }}
        >
          <div style={{ marginLeft: isMobile ? "0" : "8rem" }}>
            <h1
              className="ms-font-weight-800"
              style={{ color: "#fff", fontWeight: "800",  fontSize: isMobile? "20px": "" }}
            >
              Finance your Medical Emergencies
            </h1>
            <div className=" mb-3" style={{ textAlign: "center" }}>
              <p
                className=" fw-normal"
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: isMobile ? "27px" : "2.5rem",
                  marginTop: ".5rem",
                }}
              >
                Get Instant loans upto ₹ 5,00,000
              </p>
              <div style={{ textAlign: "center" }}>
                <span
                  style={{
                    color: "#fff",
                    display: "block",
                    fontSize: isMobile ? "21px" : "24px",
                    marginTop: "-.5px",
                    fontWeight: "500",
                  }}
                >
                  Fill up the form or call us at
                </span>
                <span
                  style={{
                    fontSize: isMobile ? "16px" : "21px",
                    color: "#fff",
                    display: "block",
                    fontWeight: "bold",
                  }}
                >
                  +91-770-393-4446
                </span>

                <img
                  src={docgirl}
                  style={{ textAlign: "center", marginTop: "-1rem", height: "307px" }}
                />
              </div>
            </div>
          </div>
          <div style={{ float: "inline-end" }}>
            <Container component="main" maxWidth="xs">
              <div
                className="provider__revolutionize__item"
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  borderRadius: "55px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <form
                  style={{ textAlign: "center", width: "133%" }}
                  onSubmit={handlesubmit}
                >
                  <TextField
                    id="firstName"
                    name="first_name"
                    label="Full Name"
                    variant="filled"
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    fullWidth
                    className={classes.nameField}
                  />
                  <FormControl
                    variant="filled"
                    fullWidth
                    className={classes.nameField}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="w-23"
                        value={phoneCode} // Concatenate phoneCode and phone here
                        classes={{ select: classes.select }}
                      >
                        {countriesList &&
                          countriesList.map((item, index) => (
                            <MenuItem
                              value={item.code}
                              key={item.code + "-" + index}
                            >
                              {item.code}
                            </MenuItem>
                          ))}
                      </Select>

                      <InputLabel
                        htmlFor="phoneNumber"
                        style={{ marginLeft: "101px" }}
                      >
                        Phone Number*
                      </InputLabel>
                      <Input
                        id="phoneNumber"
                        name="phone"
                        label="Phone Number"
                        required
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        error={phoneError}
                        style={{ flex: 1, marginLeft: 6, width: "100px" }} // Adjust the margin and flex properties
                        className={classes.nameField}
                      />
                    </div>
                    {phoneError && (
                      <FormHelperText error>
                        Please enter a valid phone number.
                      </FormHelperText>
                    )}
                  </FormControl>
                  <TextField
                    id="pin"
                    name="pin"
                    label="Pin Code"
                    variant="filled"
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    required
                    fullWidth
                    className={classes.nameField}
                  />

                  <TextField
                    id="chiefComplaints"
                    name="message"
                    label="Address"
                    variant="filled"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    fullWidth
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    className={classes.nameField}
                  />

                  <TextField
                    id="city"
                    name="city"
                    label="Enter your city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    variant="filled"
                    required
                    fullWidth
                    style={{ marginBottom: "20px", backgroundColor: "white" }} // Add backgroundColor
                    className={classes.nameField}
                  />
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Submit
                  </Button>
                </form>
              </div>
            </Container>
          </div>
        </div>
      </section>
      {/* <section
        style={{
          background: "linear-gradient(120deg, rgb(237, 233, 233), #edf2f7)",
        }}
        className="ms-apart-section"
      >
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12 text-center">
              <h2 className="ms-font-weight-600 ms-primary-color">
                How it works?
              </h2>
            </div>
          </div>
          <div className="row g-9 justify-content-center">
            {medicals?.length > 0 ? (
              medicals.map((item, index) => (
                <div className="col-md-4 col-sm-6 mb-4" key={index}>
                  <div className="provider__revolutionize__item h-100">
                    <div className="provider__revolutionize__icon text-center">
                      <img src={item?.icon} alt={item?.alt} />
                    </div>
                    <h4 className="provider__revolutionize__title text-center ms-font-weight-600 mt-3 mb-4 ms-primary-color">
                      {item?.title}
                    </h4>
                    <h6 className="provider__revolutionize__text ms-2 mb-0 fw-normal text-center">
                      {item?.text}
                    </h6>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center">No items to display</div>
            )}
          </div>
        </div>
      </section> */}

      <section className="ms-content-section">
        <div className="container">
          <h3 className="mb-4 ms-font-weight-600 ms-primary-color">
            Medical Loans in India
          </h3>
          <h6 className="fw-normal" style={{ color: "black" }}>
            With the Increasing uncertainties in life and the spread of
            coronavirus, covering our medical costs is of utmost importance. Not
            everyone in India can afford to have a good premium insurance plan
            to cover treatment costs at a big hospital. It is at times like
            these, RxOne comes to your rescue by helping you get easy and
            hassle-free medical emergency loans.
          </h6>
          <br />
          <h6 className="fw-normal" style={{ color: "black" }}>
            When a medical emergency arrives, worrying about funds should be the
            last thing on your mind so fill your medical loan application form
            now and finance your medical expenses, the cycle of infertility
            treatment, or even cosmetic surgeries
          </h6>
          <br />
          <h6 className="fw-normal" style={{ color: "black" }}>
            RxOne helps you to get easy access to an unsecured personal loan
            for medical treatment for a tenure of 6 months to 36 months starting
            from 20,000 up to 5,00,000 So, check your eligibility. compare
            interest rates, and apply for a medical loan in India to all banks
            and NBFC's using RxOne{" "}
          </h6>
        </div>
      </section>

      <h5
        className="mb-4 ms-font-weight-600 ms-primary-color"
        style={{ marginLeft: "1rem", fontSize: "1.4rem" }}
      >
        Frequently Asked Questions
      </h5>

      <ul style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
        {medi.map((faq) => (
          <li className="languages card" key={faq.id}>
            <div>
              <strong>{faq.question}</strong>
              <button
                onClick={() => toggleQuestion(faq.id)}
                style={{
                  border: "none",
                  background: "none",
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  marginTop: "-22px",
                }}
              >
                <span>{expandedQuestions.includes(faq.id) ? "▲" : "▼"}</span>
              </button>
            </div>
            {expandedQuestions.includes(faq.id) && <p>{faq.answer}</p>}
          </li>
        ))}
      </ul>
    </>
  );
};
export default Medicalloan;
