import React from 'react'
import {Modal, Button} from "react-bootstrap"

function ProviderModal(props) {
  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
    <h5 className='transition fw-bold'>Thanks !</h5> 
    </Modal.Header>
    <Modal.Body>
      <p className='transition text-center'>
        We have received your request, we will reach out to you shortly to schedule the demo.
      </p>
    </Modal.Body>
  </Modal>
  )
}

export default ProviderModal