import React from "react";
import "../../Css/companypage.css";
function Terms() {
  return (
    <>
      <div className="pp__first">
        <h1>Terms and Usage</h1>
        <div className="tt__firstpara">
          {/* <h4>INTRODUCTION OF RX ONE CARE PVT LTD/ABOUT US</h4>
                 <p>Rx One Care Pvt Ltd is a facilitator that provides a platform called "RxOne" which consists of software system built on Cloud technologies and Mobile technologies and user can access its features and associated data from Mobile application and internet both for providing and accessing information on healthcare, prevention, symptoms and cure of diseases and ailments and directory of healthcare service providers, merchant directory of vendors of medical equipment, pharmacy and other supplies. It gives a platform to book appointment with health care service providers registered on the platform. It allows service provider /vendor to publish on the Website its contact information, its location, key doctor's profile. They can also advertise their services, health packages, events, seminar information, and fee schedule and timings for clinic, doctors & consultations with its user id and password. We cannot guarantee the ability or intent of the Practitioner(s) or Provider(s) to fulfil their obligations towards you. We can only provide you value added services which connects Users directly to the Practitioner. It helps patients to find the best doctors and book appointments with them. Find doctors by their Specialty, Location or by Affiliated Clinics & Hospitals.</p> */}
          <strong>Last Revision Date: 24 th September 2024</strong>
          <br />
          <br />

          <p>
            PLEASE READ THE TERMS OF SERVICE CAREFULLY. BY USING THIS PLATFORM
            OR AVAILING SERVICES FROM THIS PLATFORM YOU AGREE TO BE BOUND BY ALL
            OF THE TERMS AND CONDITIONS OF THISAGREEMENT.
          </p>
          <br />
        </div>
        <div className="tt__secondpara">
          <ol>
            <li>
              <strong> APPLICABILITY</strong>
              <br />
              <br />
              <br />
              <ol type="." className="list__gap">
                <li>
                  {" "}
                  The website www.rxone.app, and the mobile application “RxOne –
                  Care to Cure” are an internet-based Platform (collectively
                  referred to as “Platform”), which are owned and operated by Rx
                  One Care Private Limited, a company incorporated under the
                  provisions of the Companies Act, 2013, having its corporate
                  office at HARTRON, IAMAI Plot No1, Udyog Vihar, Phase I,
                  Dundahera Village, Sector 20, Gurugram, Haryana 122022
                  hereinafter referred to as “RxOne” or “We” or “Our” or
                  “Ourselves” or “Us” or “Entity”).
                </li>
                <li>
                  By accessing, browsing or in any way transacting on the
                  Platform, or availing any services hosted or managed remotely
                  through the Platform, You and such natural persons who have
                  agreed to use the Platform (inclusive of new User(s), existing
                  User(s), end User(s), individual(s), company(s), a patient or
                  his/her representatives/ attendant/ relative/ friend or
                  affiliates or any other such person(s)) referred to as “You”
                  or “Your” or “Yourself” or “User”, which terms shall also
                  include natural persons who are accessing the Platform merely
                  as visitors), You signify Your agreement to be bound and
                  governed by the following terms and conditions of this Terms
                  of Service Agreement (hereinafter referred to as the
                  “Agreement”), including the applicable policies which are
                  incorporated herein by way of reference.
                </li>
                <li>
                  RxOne reserves the right to change or revise the terms and
                  conditions of this Agreement at any time by posting any
                  changes or a revised Agreement on this Platform. RxOne will
                  alert You that changes or revisions have been made by
                  indicating on the top of this Agreement the date it was last
                  revised. The changed or revised Agreement will be effective
                  immediately after it is posted on this Platform. Your use of
                  this Platform following the posting any such changes or of a
                  revised Agreement will constitute Your acceptance of any such
                  changes or revisions. RxOne encourages You to review this
                  Agreement whenever You visit the Platform to make sure that
                  You understand the terms and conditions governing use of this
                  Platform. This Agreement does not alter in any way the terms
                  or conditions of any other written agreement You may have with
                  RxOne for other services. If You do not agree to this
                  Agreement (including any referenced policies or guidelines),
                  please immediately terminate Your use of this Platform. If You
                  would like to print this Agreement, please click the print
                  button on Your browser toolbar.
                </li>

                <li>
                  The Agreement is published in compliance of, and is governed
                  by the provisions of Indian law, including but not limited to:
                </li>
                <ol type="I" className="list__gap">
                  <li>The Indian Contract Act, 1872;</li>
                  <li>
                    The (Indian) Information Technology Act, 2000 (the “IT
                    Act”), and the rules, regulations, guidelines and
                    clarifications framed there under, including the (Indian)
                    Information Technology (Reasonable Security Practices and
                    Procedures and Sensitive Personal Information) Rules, 2011
                    (the &quot;SPI Rules&quot;) and the (Indian) Information
                    Technology (Intermediaries Guidelines) Rules, 2011 (the
                    &quot;IG Rules&quot;);
                  </li>
                  <li>TThe Consumer Protection Act, 1986; and</li>
                  <li>Any other such laws, rules or act as applicable.</li>
                </ol>
                <br />
              </ol>
            </li>

            {/* ---------- section2----------  */}

            <li>
              <strong> IMPORTANT NOTICE </strong>
              <br />
              <ol type="." className="list__gap">
                <li>
                  RxOne is not a registered medical practitioner and does not
                  provide any medical advice or consultation. RxOne only
                  provides a medium to connect You with the healthcare provider
                  (doctor and/or hospital) listed on the Platform. Any advice
                  provided to You by the healthcare provider is their own
                  opinion and the Entity shall not be held responsible for
                  accuracy/correctness of the same.
                </li>
                <li>
                  The Platform may contain content, text, data, graphics,
                  images, information, suggestions, guidance, and other material
                  (collectively referred to as &quot;Information&quot;) shared
                  by healthcare providers. The provision of such Information
                  does not create a licensed medical professional/patient
                  relationship, between RxOne and You and does not constitute an
                  opinion, medical advice, or diagnosis or treatment of any
                  particular condition. This information is only provided to
                  assist You with locating appropriate medical care from a
                  healthcare provider.
                </li>
                <li>
                  The Services provided on the Platform are not intended to be a
                  substitute for contacting emergency healthcare. If You are
                  facing any medical emergency (either Yourself or on any other
                  person&#39;s behalf), please contact an ambulance service or
                  hospital directly.
                </li>
              </ol>
            </li>

            {/* ---------- section3----------  */}

            <li>
              <strong> CONDITIONS OF USE</strong>
              <br />
              <ol type="." className="list__gap">
                <li>
                  You must be 18 years of age or older to register and use the
                  services, or visit or use the Platform in any manner. By
                  registering, visiting and using the Platform or accepting this
                  Agreement, You represent and warrant to the RxOne that You are
                  18 years of age or older, and that You have the right,
                  authority and capacity to use the Platform and the services
                  available through the Platform, and agree to and abide by this
                  Agreement.
                </li>
                <li>
                  The registration on or use/ access of the Platform is only
                  available to natural persons, other than those who are
                  ‘incompetent to contract’ under the Contract Act. That is,
                  persons including minors, un-discharged insolvents etc. are
                  not eligible to register on, or use/ access the Platform. By
                  registering, accessing or using the Platform, You accept the
                  terms of this Agreement and represent and warrant to the
                  Entity that You are ‘competent to contract’ under the Contract
                  Act and have the right, authority and capacity to use the
                  Platform and agree to and abide by these Terms of Service.
                </li>
                <li>
                  You agree and acknowledge that You would (i) create only 1
                  (one) account; (ii) provide accurate, truthful, current and
                  complete information when creating Your account and in all
                  Your dealings through the Platform; (iii) maintain and
                  promptly update Your account information; (iv) maintain the
                  security of Your account by not sharing Your password with
                  others and restricting access to Your account and Your
                  computer; (v) promptly notify the Entity if You discover or
                  otherwise suspect any security breaches relating to the
                  Platform; and (vi) take responsibility for all the activities
                  that occur under Your account and accept all risk of
                  unauthorized access.
                </li>
                <li>
                  You shall not attempt to gain unauthorized access to any
                  portion or feature of the Platform, or any other systems or
                  networks connected to the Platform or to any server, computer,
                  network, or to any of the services offered on or through the
                  Platform, by hacking, password &quot;mining&quot; or any other
                  illegitimate means.
                </li>
                <li>
                  The Platform uses temporary cookies to store certain data
                  (that is not sensitive personal data or information) that is
                  used by the Entity for the technical administration of the
                  Platform, research and development, and for User
                  administration. In the course of serving advertisements or
                  optimizing services to You, the Entity may allow authorized
                  third parties to place or recognize a unique cookie on the
                  Your browser. The Entity does not store personally
                  identifiable information in the cookies.
                </li>
              </ol>
              <br />
            </li>
            <li>
              <strong>CONTENT POLICY </strong>
              <br />
              <ol type="." className="list__gap">
                <li>
                  The Information that You obtain or receive from RxOne, and its
                  employees, contractors, partners, sponsors, advertisers,
                  licensors or otherwise on the Platform is for informational
                  purposes only. RxOne makes no guarantees, representations or
                  warranties, whether expressed or implied, with respect to
                  professional qualifications, quality of work, expertise,
                  reliability, accuracy, timeliness, usefulness, adequacy,
                  completeness or other information provided on the Platform.
                </li>
                <li>
                  The Platform reserves the right, but has no obligation, to
                  monitor the content posted on the Platform. Rx One shall have
                  the right to remove or edit any content that in its sole
                  discretion violates, or is alleged to violate, any applicable
                  law or either the spirit or letter of this Agreement.
                </li>
              </ol>
              <br />
            </li>

            {/* -------------- section 3 ------------  */}

            <li>
              <strong>TERMS AND CONDITIONS FOR TELE-CONSULTATION</strong>
              <br />
              <ol type="." className="list__gap">
                <li>
                  RxOne tele-consultation is intended for general purposes only
                  and is not to be used in emergencies/serious illnesses
                  requiring in-person consultation.
                </li>

                <li>
                  You hereby attest that You have requested and authorize the
                  healthcare provider for an online consult for Yourself or any
                  dependent persons. You hereby agree that the healthcare
                  provider will reach his or her conclusions regarding Your
                  medical diagnosis based solely on the information provided by
                  You.
                </li>
                <li>You understand and agree that:</li>
                <ol type="I" className="list__gap">
                  <li>
                    Tele-consultation is solely based on the information
                    provided by You and in the absence of a physical evaluation,
                    the healthcare provider may not be aware of certain facts
                    that may limit or affect his or her assessment or diagnosis
                    of Your condition and recommended treatment.
                  </li>
                  <li>
                    Tele-consultation is very different from a regular face to
                    face examination and that the healthcare provider is limited
                    by the written information or imaging provided by You.
                    Accordingly, the diagnosis You will receive is limited and
                    provisional.
                  </li>
                  <li>
                    Tele-consultation is not intended to replace a full medical
                    face to face evaluation by a healthcare provider.
                  </li>
                  <li>
                    The healthcare provider is only rendering the
                    tele-consultation and does not assume any responsibility for
                    You continued medical care or treatment.
                  </li>
                  <li>
                    The medical information will be handled with strict
                    confidentiality. However, You understand that there are
                    risks associated with any electronic transfer process from
                    one location to another.
                  </li>
                  <li>
                    You solely assume the risk of the limitations set forth
                    herein, and You further understand that no warranty or
                    guarantee has been made to You concerning any particular
                    result related to Your conditions or diagnosis.
                  </li>
                </ol>
                <li>
                  In case the healthcare provider adjudges that a physical
                  examination would be required and advises &quot;in-person
                  consultation&quot;, it is Your responsibility to book an
                  appointment for physical examination and in-person
                  consultation. In case of any negligence on Your part in acting
                  on the same and the condition of the patient deteriorates,
                  RxOne shall not be held liable.
                </li>
                <li>
                  You hereby agree that data with respect to tele-consultations
                  may be retained By RxOne in our database, for legal and/or
                  administrative requirements for future use.
                </li>
              </ol>
              <br />
            </li>
            <li>
              <strong>TERMS AND CONDITIONS FOR IN-PERSON CONSULTATIONS</strong>
              <br />
              <ol className="list__gap">
                <li>
                  RxOne does not guarantee or endorse the authenticity, quality,
                  safety or legality of any healthcare provider or the services
                  provided or purported to be provided by any healthcare
                  provider, the accuracy of any listings or any healthcare
                  provider data we may provide You, or the ability of any
                  healthcare providers to complete a transaction.
                </li>
                <li>
                  You hereby agree to engage any health care provider to provide
                  Services to You from the Platform, You do so at Your own risk.
                  In this regard, RxOne recommends that You undertake Your own
                  research into any healthcare provider listed on the Platform
                  before engaging any healthcare provider from this Platform.
                  You should be aware that the results of any search You perform
                  on the Platform for healthcare providers should not be
                  construed as an endorsement by RxOne of any particular
                  healthcare provider or constituting any comparative ranking of
                  any healthcare providers.
                </li>
                <li>
                  You hereby agree that the healthcare provider will reach his
                  or her conclusions regarding Your medical diagnosis based
                  solely on the information provided by You.
                </li>
              </ol>
            </li>
            <li>
              <strong> TERMS AND CONDITIONS FOR DISCOVER CARE PATH</strong>
              <br />
              <ol className="list__gap">
                <li>
                  The Platform feature “Discover Care Path” uses Generative Pre
                  Trained (GPT) AI model for developing treatment plans. This
                  feature is still in development and is intended as a decision
                  support tool, not a replacement for professional medical
                  judgment. The tool aims to assist You by generating diagnostic
                  hypotheses based on patient symptoms and clinical data,
                  potentially reducing the time to diagnosis for common
                  diseases. Further validation on real clinical data and
                  comparison with human expert diagnoses are necessary to fully
                  assess its performance and potential impact on patient care.
                </li>
                <li>
                  You hereby agree to use this Platform feature at Your own
                  risk. The Platform and its content are provided “as is,” and
                  “as available” without any guarantees. RxOne does not
                  guarantee accuracy or completeness of the responses generated.
                  RxOne and its licensors won’t be responsible for any damages
                  that result from using or being unable to use the Platform.
                </li>
              </ol>
            </li>
            <li>
              <strong>TERMS AND CONDITIONS FOR EXPERT ADVICE:</strong>
              <br />
              <ol className="list__gap">
                <li>
                  RxOne through its employees, contractors, partners, sponsors,
                  advertisers, licensors may provide the User with Information
                  about healthcare providers and other related services. These
                  include:
                </li>
                <ol type="a" className="list__gap">
                  <li>
                    Information about healthcare providers, doctors, and the
                    services they offer
                  </li>
                  <li>
                    Consultation with our coordinator to discuss healthcare
                    providers, and their available services.
                  </li>
                  <li>
                    Assistance in selecting the optimal medical solution for
                    your needs.
                  </li>
                  <li>
                    Obtain an individualized treatment program from healthcare
                    providers.
                  </li>
                  <li>Assistance in determining treatment costs.</li>
                  <li>Assistance in making payments for a medical services.</li>
                  <li>Schedule a visit to your chosen healthcare provider.</li>
                  <li>Request a &#39;Tele/Video Consultation.&#39;</li>
                </ol>
                <li>
                  RxOne does not assign Users to specific healthcare providers.
                  RxOne provides a curated list of healthcare providers based on
                  Your preferences. Our goal is to assist You in finding the
                  best healthcare option, considering factors such as language,
                  country, medical needs, financial preferences, and other
                  circumstances.
                </li>
                <li>
                  Furthermore, RxOne facilitates connections between RxOne’s
                  Users and selected healthcare providers, offering support
                  throughout communication, assistance with visit arrangements,
                  and continued support even after you return home. Throughout
                  your interaction with the Platform, our dedicated RxOne
                  coordinators are available to assist.
                </li>
                <li>
                  RxOne operates independently of clinics, and we do not
                  guarantee the quality or specialization of their services. We
                  are not intermediaries between Users and healthcare providers,
                  and we do not directly provide medical services. The
                  information on the platform does not constitute medical advice
                  and should not be used as a guide for action.
                </li>
              </ol>
              <br />
            </li>

            {/* ---------------------section -------------------  */}

            <li>
              <strong> TERMS AND CONDITIONS FOR THIRD PARTY SERVICES:</strong>
              <br />
              <ol className="list__gap">
                <li>
                  RxOne provides the option to engage third party services
                  through it’s Platform, such as:
                </li>
                <ol type="a" className="list__gap">
                  <li>Logistic and Boarding services;</li>
                  <li>Medical Visa services;</li>
                  <li>Diagnostic Testing services;</li>
                  <li>Pharmacy services; and</li>
                  <li>Medical Loan services;</li>
                </ol>
                <li>
                  RxOne has no liability for any third party website/platform
                  that you may access through this Platform. When you access a
                  third party website/platform, please understand that it is
                  independent from RxOne, and that RxOne has no control over the
                  third party website or its content.
                </li>
                <li>
                  In addition, RxOne does not endorse any third party whose
                  website/platform may be linked from this Platform. RxOne does
                  not and cannot ensure that the propriety, security or accuracy
                  of any third party website/platform and we encourage you to
                  review the posted policies of any website/platform that you
                  visit.
                </li>
                <li>
                  You may choose to use other services provided by third parties
                  in conjunction with your access to and use of this Platform.
                  Such services may include, but are not limited to, Medical
                  Visa Services, Diagnostic Testing Services, Pharmacy Services,
                  Medical Loan services and any other third-party offerings.
                </li>
                <li>
                  Any links to such services are provided for your convenience
                  only. RxOne has no control over the contents of these
                  services, and RxOne accepts no responsibility for them or for
                  any loss or damage that may arise from your use of them.
                </li>
                <li>
                  Such services may require you to submit your information to a
                  third party, which may result in a copy of your information
                  being used and/or stored by a third party. If you decide to
                  access any such services in conjunction with your access to
                  and use of this Platform, you do so entirely at your own risk
                  and subject to the terms and conditions of use for such
                  services.
                </li>
              </ol>
              <br />
            </li>
            <li>
              <strong> LICENCE FOR PLATFORM ACCESS</strong>
              <br />
              <ol className="list__gap">
                <li>
                  RxOne grants You a licence to use the Platform, but not to
                  download or modify it, or any portion of it, except with
                  express written consent of RxOne and / or its affiliates, as
                  may be applicable. This licence does not include any resale or
                  commercial use of this Platform or its contents; any
                  collection and use of any service listings, descriptions, or
                  prices; any derivative use of this Platform or its contents;
                  any downloading or copying of account information for the
                  benefit of another seller; or any use of data mining, robots,
                  or similar data gathering and extraction tools.
                </li>
                <li>
                  This Platform or contents of it (including but not limited to
                  any copyrighted material, trademarks, or other proprietary
                  information) may not be reproduced, duplicated, copied, sold,
                  resold, visited, distributed or otherwise exploited for any
                  commercial purpose without express written consent of RxOne
                  and / or its affiliates, as applicable.
                </li>
                <li>
                  You cannot frame or use framing techniques to enclose any
                  trademark, logo, or other proprietary information (including
                  images, text, page layout, or form) of RxOne and its
                  affiliates without express written consent. You may not use
                  any Meta tags or any other &quot;hidden text&quot; utilising
                  RxOne or its affiliates&#39; names or trademarks without the
                  express written consent of RxOne and / or its affiliates, as
                  applicable. Any unauthorized use terminates the permission or
                  license granted by RxOne and / or its affiliates, as
                  applicable.
                </li>
                <li>
                  You are granted a limited, revocable, and non-exclusive right
                  to create a hyperlink to the Welcome page of www.rxone.app or
                  www.rxonecare.com as long as the link does not portray RxOne,
                  their affiliates, or their Services in a false, misleading,
                  derogatory, or otherwise offensive matter.
                </li>
                <li>
                  You may not use any Rx One logo or other proprietary graphic
                  or trademark as part of the link without express written
                  consent of RxOne and / or its affiliates, as may be
                  applicable.
                </li>
                <li>
                  RxOne reserves the right to charge the usage fee or download
                  fee of Platform any time as it deems fit.
                </li>
              </ol>
              <br />
            </li>
            <li>
              <strong>PAYMENT AND CANCELLATION POLICY</strong>
              <br />

              <ol className="list__gap">
                <li>
                  In the event of unavailability of the healthcare provider at
                  the time of scheduled appointment, the User shall have the
                  option to, (a) cancel the current appointment for refund or
                  (b) consult another doctor with the healthcare provider&#39;s
                  consent.
                </li>
                <li>
                  Cancellation of the appointment by the User must be done at
                  least 3 hours prior to the time booked for appointment. In
                  such an event, the entire amount will be refunded to the User
                  within 1 (one) week from the date of cancellation as per the
                  mode of refund proposed by the User. If the booking
                  confirmation gets delayed due to technical reasons or as a
                  result of incorrect e-mail ID provided by the User etc, an
                  appointment would be considered as &#39;booked&#39;. If the
                  User does not show up at the time of appointment, RxOne will
                  have right to forfeit refund of the entire amount paid by the
                  User. RxOne shall not be liable for any refunds to the User in
                  the event the User&#39;s booked slot/ time for an appointment
                  has been delayed.
                </li>
                <li>
                  Any grievances and claims related to the appointment/ refund
                  should be reported to RxOne support team support@rxone.app
                  within two (2) days of appointment date with the healthcare
                  provider.
                </li>
                <li>
                  Further, RxOne shall not be liable, under any event, for any
                  comments or feedback given by any of the Users in relation to
                  the services provided by another User. All such feedback
                  should be made in accordance with applicable law. The option
                  of Users to give feedback remains at RxOne&#39;s sole
                  discretion and may be modified or withdrawn at its sole
                  discretion. RxOne shall not be obliged to act in any manner to
                  give effect to the content of Users&#39; feedback, such as
                  suggestions for delisting of a particular healthcare provider
                  from the Platform.
                </li>
              </ol>
              <br />
            </li>
            <li>
              <strong>PRIVACY POLICY</strong>
              <br />
              <br />
              <ol className="list__gap">
                <li>
                  RxOne believes strongly in protecting User privacy and
                  providing You with notice of RxOne&#39;s use of data. Please
                  refer to RxOne’s privacy policy, incorporated by reference
                  herein, available on the Platform and the website.
                </li>
                <li>
                  By using this Platform or its Services, You acknowledge that
                  You have read and agree to be bound by the Privacy Policy.
                </li>
              </ol>
              <br />
            </li>
            <li>
              <strong>PROFILE OWNERSHIP AND EDITING RIGHTS</strong>
              <br />
              <ol className="list__gap">
                <li>
                  RxOne reserves the right of ownership of all the Patient&#39;s
                  profile and photographs and to moderate the changes or updates
                  requested by You.
                </li>
                <li>
                  RxOne has the sole discretion to publish or reject the
                  requests submitted for the respective changes or updates.
                </li>
                <li>
                  You shall be authorized under law to upload all content
                  uploaded by You as part of Your profile or otherwise while
                  using RxOne Services, and that no such content breaches any
                  third party rights, including intellectual property rights.
                </li>
                <li>
                  On breach of the foregoing representation, RxOne reserves the
                  right to modify or delete parts of Your profile information at
                  its sole discretion with or without notice to You.
                </li>
              </ol>
              <br />
            </li>
            <li>
              <strong>USE OF THE PLATFORM</strong>
              <br />
              <p>
                You hereby agree to undertake and confirm that Your use of the
                Platform shall be strictly governed by the following binding
                principles:
              </p>
              <br />
              <ol className="list__gap">
                <li>
                  You shall not host, display, upload, modify, publish,
                  transmit, update or share any information which
                </li>
                <ol type="a" className="list__gap">
                  <li>
                    belongs to another person and to which You does not have any
                    right to;
                  </li>
                  <li>
                    is grossly harmful, harassing, blasphemous, defamatory,
                    obscene, pornographic, paedophilic, libellous, invasive of
                    another&#39;s privacy, hateful, or racially, ethnically
                    objectionable, disparaging, relating or encouraging money
                    laundering or gambling, or otherwise unlawful in any manner
                    whatever; or unlawfully threatening or unlawfully harassing
                    including but not limited to &quot;indecent representation
                    of women&quot; within the meaning of the Indecent
                    Representation of Women (Prohibition) Act, 1986;
                  </li>
                  <li>is misleading in any way;</li>
                  <li>
                    is patently offensive to the online community, such as
                    sexually explicit content, or content that promotes
                    obscenity, pedophilia, racism, bigotry, hatred or physical
                    harm of any kind against any group or individual;
                  </li>
                  <li>harasses or advocates harassment of another person;</li>
                  <li>
                    involves the transmission of &quot;junk mail&quot;,
                    &quot;chain letters&quot;, or unsolicited mass mailing or
                    &quot;spamming&quot;;
                  </li>
                  <li>
                    promotes illegal activities or conduct that is abusive,
                    threatening, obscene, defamatory or libellous;
                  </li>
                  <li>
                    infringes upon or violates any third party&#39;s rights
                    [including, but not limited to, intellectual property
                    rights, rights of privacy (including without limitation
                    unauthorized disclosure of a person&#39;s name, email
                    address, physical address or phone number) or rights of
                    publicity];
                  </li>
                  <li>
                    contains restricted or password-only access pages, or hidden
                    pages or images (those not linked to or from another
                    accessible page);
                  </li>
                  <li>
                    provides material that exploits people in a sexual, violent
                    or otherwise inappropriate manner or solicits personal
                    information from anyone;
                  </li>
                  <li>
                    provides instructional information about illegal activities
                    such as making or buying illegal weapons, violating
                    someone&#39;s privacy, or providing or creating computer
                    viruses;
                  </li>
                  <li>
                    contains video, photographs, or images of another person
                    (with a minor or an adult)
                  </li>
                  <li>
                    tries to gain unauthorized access or exceeds the scope of
                    authorized access to the Website or to profiles, blogs,
                    communities, account information, bulletins, friend request,
                    or other areas of the Website or solicits passwords or
                    personal identifying information for commercial or unlawful
                    purposes from other Users;
                  </li>
                  <li>
                    engages in commercial activities and/or sales without Our
                    prior written consent such as contests, sweepstakes, barter,
                    advertising and pyramid schemes, or the buying or selling of
                    &quot;virtual&quot; Services related to the Website.
                    Throughout this Agreement, RxOne&#39;s prior written consent
                    means a communication coming from RxOne&#39;s Legal
                    Department, specifically in response to Your request, and
                    specifically addressing the activity or conduct for which
                    You seek authorization;
                  </li>
                  <li>
                    solicits gambling or engages in any gambling activity which
                    We, in Our sole discretion, believes is or could be
                    construed as being illegal;
                  </li>
                  <li>
                    interferes with another User&#39;s use and enjoyment of the
                    Website or any other individual&#39;s User and enjoyment of
                    similar services;
                  </li>
                  <li>
                    refers to any website or URL that, in Our sole discretion,
                    contains material that is inappropriate for the Website or
                    any other website, contains content that would be prohibited
                    or violates the letter or spirit of this Agreement.
                  </li>
                  <li>harm minors in any way;</li>
                  <li>
                    infringes any patent, trademark, copyright or other
                    proprietary rights or third party&#39;s trade secrets or
                    rights of publicity or privacy or shall not be fraudulent or
                    involve the sale of counterfeit or stolen Services;
                  </li>
                  <li>violates any law for the time being in force;</li>
                  <li>
                    deceives or misleads the addressee/ Users about the origin
                    of such messages or communicates any information which is
                    grossly offensive or menacing in nature;
                  </li>
                  <li>impersonate another person;</li>
                  <li>
                    contains software viruses or any other computer code, files
                    or programs designed to interrupt, destroy or limit the
                    functionality of any computer resource; or contains any
                    trojan horses, worms, time bombs, cancelbots, easter eggs or
                    other computer programming routines that may damage,
                    detrimentally interfere with, diminish value of,
                    surreptitiously intercept or expropriate any system, data or
                    personal information;
                  </li>
                  <li>
                    threatens the unity, integrity, defence, security or
                    sovereignty of India, friendly relations with foreign
                    states, or public order or causes incitement to the
                    commission of any cognizable offence or prevents
                    investigation of any offence or is insulting any other
                    nation.
                  </li>
                  <li>shall not be false, inaccurate or misleading;</li>
                  <li>
                    shall not, directly or indirectly, offer, attempt to offer,
                    trade or attempt to trade in any item, the dealing of which
                    is prohibited or restricted in any manner under the
                    provisions of any applicable law, rule, regulation or
                    guideline for the time being in force.
                  </li>
                  <li>
                    shall not create liability for Us or cause Us to lose (in
                    whole or in part) the services of Our internet service
                    provider (&quot;ISPs&quot;) or other suppliers
                  </li>
                </ol>
                <li>
                  You shall not use any &quot;deep-link&quot;,
                  &quot;page-scrape&quot;, &quot;robot&quot;, &quot;spider&quot;
                  or other automatic device, program, algorithm or methodology,
                  or any similar or equivalent manual process, to access,
                  acquire, copy or monitor any portion of the Platform or any
                  Content, or in any way reproduce or circumvent the
                  navigational structure or presentation of the Platform or any
                  content, to obtain or attempt to obtain any materials,
                  documents or information through any means not purposely made
                  available through the Platform. We reserve our right to bar
                  any such activity.
                </li>
                <li>
                  You shall not attempt to gain unauthorized access to any
                  portion or feature of the Platform, or any other systems or
                  networks connected to the Platform or to any server, computer,
                  network, or to any of the services offered on or through the
                  Platform by hacking, password &quot;mining&quot; or any other
                  illegitimate means.
                </li>
                <li>
                  You shall not probe, scan or test the vulnerability of the
                  Platform or any network connected to the Platform nor breach
                  the security or authentication measures on the Platform or any
                  network connected to the Platform. You may not reverse
                  look-up, trace or seek to trace any information on any other
                  User of or visitor to Platform, or any other customer,
                  including any account on the Platform not owned by You, to its
                  source, or exploit the Platform or any service or information
                  made available or offered by or through the Platform, in any
                  way where the purpose is to reveal any information, including
                  but not limited to personal identification or information,
                  other than Your own information, as provided for by the
                  Platform.
                </li>
                <li>
                  You shall not make any negative, denigrating or defamatory
                  statement(s) or comment(s) about Us or the brand name or
                  domain name used by Us including the terms or otherwise engage
                  in any conduct or action that might tarnish the image or
                  reputation of any Users of RxOne or healthcare providers on
                  the Platform or otherwise tarnish or dilute any trade or
                  service marks, trade name and/or goodwill associated with such
                  trade or service marks, trade name as may be owned or used by
                  Us. You agree that You will not take any action that imposes
                  an unreasonable or disproportionately large load on the
                  infrastructure of the Platform or systems or networks, or any
                  systems or networks connected to the Platform.
                </li>
                <li>
                  You agree not to use any device, software or routine to
                  interfere or attempt to interfere with the proper working of
                  the Platform or any transaction being conducted on the
                  Platform, or with any other person&#39;s use of the Platform.
                </li>
                <li>
                  You may not forge headers or otherwise manipulate identifiers
                  in order to disguise the origin of any message or transmittal
                  You send to us on or through the Platform or any service
                  offered on or through the Platform.
                </li>
                <li>
                  You may not pretend that You are, or that You represent,
                  someone else, or impersonate any other individual or entity.
                </li>
                <li>
                  You may not use the Platform or any content for any purpose
                  that is unlawful or prohibited by this Agreement, or to
                  solicit the performance of any illegal activity or other
                  activity which infringes the rights of RxOne, its Users and /
                  or others.
                </li>
                <li>
                  You shall at all times ensure full compliance with the
                  applicable provisions of the Information Technology Act, 2000
                  and rules thereunder as applicable and as amended from time to
                  time and also all applicable Domestic laws, rules and
                  regulations (including the provisions of any applicable
                  Exchange Control Laws or Regulations in Force) and
                  International Laws, Foreign Exchange Laws, Statutes,
                  Ordinances and Regulations regarding Your use of Our service
                  and Your listing, purchase, solicitation of offers to
                  purchase, and sale of services. You shall not engage in any
                  transaction in an item or service, which is prohibited by the
                  provisions of any applicable law including exchange control
                  laws or regulations for the time being in force.
                </li>
                <li>
                  Solely to enable Us to use the information You supply Us with,
                  so that we are not violating any rights You might have in Your
                  Information, You agree to grant us an exclusive, worldwide,
                  perpetual, irrevocable, royalty-free, sub-licensable (through
                  multiple tiers) right to exercise the copyright, publicity,
                  database rights or any other rights You have in Your
                  Information, in any media now known or not currently known,
                  with respect to Your Information. We will only use Your
                  information in accordance with this Agreement and Privacy
                  Policy applicable to use of the Platform.
                </li>
                <li>
                  You shall not engage in advertising to, or solicitation of,
                  other Users of the Platform to buy or sell any Services,
                  including, but not limited to, Services related to that being
                  displayed on the Platform or related to Us. You may not
                  transmit any chain letters or unsolicited commercial or junk
                  mail to other Users via the Platform. It shall be a violation
                  of these this Agreement to use any information obtained from
                  the Platform in order to harass, abuse, or harm another
                  person, or in order to contact, advertise to, solicit, or sell
                  to another person without Our prior explicit consent. You
                  understand that we have the right at all times to disclose any
                  information (including the identity of the persons providing
                  information or materials on the Platform) as necessary to
                  satisfy any law, regulation or valid governmental request.
                  This may include, without limitation, disclosure of the
                  information in connection with investigation of alleged
                  illegal activity or solicitation of illegal activity or in
                  response to a lawful court order or subpoena. In addition, We
                  can (and You hereby expressly authorize Us to) disclose any
                  information about You to law enforcement or other government
                  officials, as we, in Our sole discretion, believe necessary or
                  appropriate in connection with the investigation and/or
                  resolution of possible crimes, especially those that may
                  involve personal injury.
                </li>
                <li>
                  Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR
                  THE CONTENT OF THE MATERIALS YOU POST ON THE PLATFORM AND IN
                  YOUR PRIVATE MESSAGES. PLEASE BE ADVISED THAT SUCH CONTENT
                  POSTED DOES NOT NECESSARILY REFLECT RXONE&#39;S VIEWS. IN NO
                  EVENT SHALL RXONE ASSUME OR HAVE ANY RESPONSIBILITY OR
                  LIABILITY FOR ANY CONTENT POSTED OR FOR ANY CLAIMS, DAMAGES OR
                  LOSSES RESULTING FROM USE OF CONTENT AND/OR APPEARANCE OF
                  CONTENT ON THE PLATFORM.
                </li>
              </ol>
              <br />
            </li>
            <li>
              <strong> CUSTOMER SOLICITATION.</strong>
              <br />
              <br />
              <ol className="list__gap">
                <li>
                  Unless You notify of Your desire to opt out from further
                  direct communications and solicitations from RxOne, You hereby
                  agree to continue to receive further emails and call
                  solicitations from RxOne and its designated in house or third
                  party call team(s).
                </li>
                <li>
                  OPT OUT PROCEDURE: We provide 2 easy ways to opt out of from
                  future solicitations.
                </li>
                <ol type="a" className="list__gap">
                  <li>
                    You may use the opt out link found in any email solicitation
                    that You may receive.
                  </li>
                  <li>
                    You may also choose to opt out, via sending Your email
                    addressed to: support@rxone.app
                  </li>
                </ol>
              </ol>
              <br />
            </li>
            <li>
              <strong> PROPRIETARY RIGHTS.</strong>
              <br />
              <br />
              <ol className="list__gap">
                <li>
                  RxOne has proprietary rights in the Services. You may not
                  copy, reproduce, resell or redistribute any services by RxOne.
                </li>
                <li>
                  RxOne also has rights to all trademarks and trade dress and
                  specific laYouts of this Platform, including calls to action,
                  text placement, images and other information.
                </li>
              </ol>
              <br />
            </li>
            <li>
              <strong> DISCLAIMER OF WARRANTIES</strong>
              <br />
              <br />
              <ol className="list__gap">
                <li>
                  Your use of this Platform and/or services are at Your sole
                  risk. The Platform and services are offered on an &quot;as
                  is&quot; and &quot;as available&quot; basis. RxOne expressly
                  disclaims all warranties of any kind, whether express or
                  implied, including, but not limited to, implied warranties of
                  merchantability, fitness for a particular purpose and
                  non-infringement with respect to the services or Platform
                  content, or any reliance upon or use of the Platform content
                  or services. (&quot;services&quot; include trial services.)
                </li>
                <li>
                  Without limiting the generality of the foregoing, RxOne makes
                  no warranty:
                </li>
                <ol className="list__gap">
                  <li>
                    That the information provided on this Platform is accurate,
                    reliable, complete, or timely.
                  </li>
                  <li>
                    That the links to third-party websites are to information
                    that is accurate, reliable, complete, or timely.
                  </li>
                  <li>
                    No advice or information, whether oral or written, obtained
                    by You from this Platform will create any warranty not
                    expressly stated herein.
                  </li>
                  <li>
                    As to the results that may be obtained from the use of the
                    services or that defects in services will be corrected.
                  </li>
                  <li>
                    Regarding any services purchased or obtained through the
                    Platform.
                  </li>
                  <li>
                    Some jurisdictions do not allow the exclusion of CERTAIN
                    WARRANTIES. Therefore, SOME OF THE ABOVE EXCLUSIONS MAY NOT
                    APPLY TO YOU.
                  </li>
                </ol>
              </ol>
              <br />
            </li>
            <li>
              <strong> LIMITATION OF LIABILITY</strong>
              <br />
              <br />
              <ol className="list__gap">
                <li>
                  The entire liability, and Your exclusive remedy, in law, in
                  equity, or otherwise, with respect to the Platform content and
                  services and/or for any breach of this agreement is solely
                  limited to the amount You paid, for services purchased via the
                  Platform.
                </li>
                <li>
                  RxOne shall not be liable for any direct, indirect,
                  incidental, special or consequential damages in connection
                  with this Agreement or the services in any manner, including
                  liabilities resulting from.
                </li>
                <ol className="list__gap">
                  <li>
                    the use or the inability to use the Platform content or
                    services;
                  </li>
                  <li>the cost of procuring substitute services or content;</li>
                  <li>
                    any services purchased or obtained or transactions entered
                    into through the Platform; or
                  </li>
                  <li>
                    any lost profits You allege. (some jurisdictions do not
                    allow the limitation or exclusion of liability for
                    incidental or consequential damages so some of the above
                    limitations may not apply to You.)
                  </li>
                </ol>
                <li>
                  This (software service provider) and any applicable Service
                  Levels do not apply to any performance or availability issues
                  such as:
                </li>
                <ol className="list__gap">
                  <li>
                    Due to factors outside our reasonable control (for example,
                    natural disaster, war, acts of terrorism, riots, government
                    action, or a network or device failure external to our data
                    centers, including at Your site or between Your site and our
                    data center);
                  </li>
                  <li>
                    That result from the use of services, hardware, or software
                    not provided by us, including, but not limited to, issues
                    resulting from inadequate bandwidth or related to
                    third-party software or services;
                  </li>
                  <li>
                    Caused by Your use of a Service after we advised You to
                    modify Your use of the Service, if You did not modify Your
                    use as advised;
                  </li>
                  <li>
                    During or with respect to preview, pre-release, beta or
                    trial versions of a Service, feature or software (as
                    determined by us) or to purchases made using Microsoft
                    subscription credits;
                  </li>
                  <li>
                    That result from Your unauthorized action or lack of action
                    when required, or from Your employees, agents, contractors,
                    or vendors, or anyone gaining access to our network by means
                    of Your passwords or equipment, or otherwise resulting from
                    Your failure to follow appropriate security practices;
                  </li>
                  <li>
                    That result from Your failure to adhere to any required
                    configurations, use supported Platforms, follow any policies
                    for acceptable use, or Your use of the Service in a manner
                    inconsistent with the features and functionality of the
                    Service (for example, attempts to perform operations that
                    are not supported) or inconsistent with our published
                    guidance;
                  </li>
                  <li>
                    That result from faulty input, instructions, or arguments
                    (for example, requests to access files that do not exist);
                  </li>
                  <li>
                    That result from Your attempts to perform operations that
                    exceed prescribed quotas or that resulted from our
                    throttling of suspected abusive behavior;
                  </li>
                  <li>
                    Due to Your use of Service features that are outside of
                    associated Support Windows; or
                  </li>
                  <li>
                    For licenses reserved, but not paid for, at the time of the
                    Incident.
                  </li>
                </ol>
              </ol>
              <br />
            </li>

            {/* -------------------section--------------  */}

            <li>
              <strong> INDEMNIFICATION</strong>
              <br />
              <br />
              <ol className="list__gap">
                <li>
                  You will release, indemnify, defend and hold harmless RxOne
                  and any of its contractors, agents, employees, officers,
                  directors, shareholders, affiliates and assigns from all
                  liabilities, claims, damages, costs and expenses, including
                  reasonable attorneys&#39; fees and expenses, of third parties
                  relating to or arising out of (1) this Agreement or the breach
                  of Your warranties, representations and obligations under this
                  Agreement; (2) the Platform content or Your use of the
                  Platform content; (3) the Services or Your use of the Services
                  (including Trial Services); (4) any intellectual property or
                  other proprietary right of any person or entity; (5) Your
                  violation of any provision of this Agreement; or (6) any
                  information or data You supplied to RxOne.
                </li>
                <li>
                  In case RxOne is threatened with suit or sued by any third
                  party, RxOne may seek written assurances from You concerning
                  Your promise to indemnify RxOne; Your failure to provide such
                  assurances may be considered by RxOne to be a material breach
                  of this Agreement.
                </li>
                <li>
                  RxOne will have the right to participate in any defense
                  presented by You of a third-party claim related to Your use of
                  any of the Platform content or Services, with counsel of
                  RxOne’s choice at its expense.
                </li>
                <li>
                  RxOne will reasonably cooperate in any defense presented by
                  You of a third-party claim at Your request and expense. You
                  will have sole responsibility to defend RxOne against any
                  claim, but You must receive RxOne’s prior written consent
                  regarding any related settlement.
                </li>
                <li>
                  The terms of this provision will survive any termination or
                  cancellation of this Agreement or Your use of the Platform or
                  Services.
                </li>
              </ol>
              <br />
            </li>
            <li>
              <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
              <br />
              <br />
              <ol className="list__gap">
                <li>
                  It is hereby specifically recorded that the copyright and
                  other Intellectual Property on the Platform is the sole and
                  exclusive property of RxOne.
                </li>
                <li>
                  All Users of the Platform represent and warrant that RxOne is
                  authorized by such third parties to upload the content or
                  information about any products or services on the Platform for
                  license of use to the end customers.
                </li>
                <li>
                  Intellectual Property Rights for the purpose of this Agreement
                  shall always mean and include copyrights whether registered or
                  not, patents including rights of filing patents, trademarks,
                  trade names, trade dresses, house marks, collective marks,
                  associate marks and the right to register them, designs both
                  industrial and laYout, geographical indicators, moral rights,
                  broadcasting rights, displaying rights, distribution rights,
                  selling rights, abridged rights, translating rights,
                  reproducing rights, performing rights, communicating rights,
                  adapting rights, circulating rights, protected rights, joint
                  rights, reciprocating rights, infringement rights.
                </li>
                <li>
                  All those Intellectual Property rights arising as a result of
                  domain names, internet or any other right available under
                  applicable law shall vest in the domain of RxOne, as the owner
                  of such domain name. The Parties hereto agree and confirm that
                  no part of any Intellectual Property rights mentioned
                  hereinabove is transferred in the name of User and any
                  intellectual property rights arising as a result of these
                  presents shall also be in the absolute ownership, possession
                  and our control or control of its licensors, as the case may
                  be.
                </li>
              </ol>
              <br />
            </li>
            <li>
              <strong> AGREEMENT TO BE BOUND</strong>
              <br />
              <br />
              <ol className="list__gap">
                <li>
                  By using this Platform or its Services, You acknowledge that
                  You have read and agree to be bound by this Agreement and all
                  terms and conditions on this Platform.
                </li>
              </ol>
              <br />
            </li>

            <li>
              <strong> GENERAL</strong>
              <br />
              <br />
              <p>
                <strong>Force Majeure- </strong>Rx One Care Pvt Ltd will not be
                deemed in default hereunder or held responsible for any
                cessation, interruption or delay in the performance of its
                obligations hereunder due to earthquake, flood, fire, storm,
                natural disaster, act of God, war, terrorism, armed conflict,
                labor strike, lockout, or boycott.
              </p>
              <p>
                <strong>Cessation of Operation- </strong>Rx One Care Pvt Ltd may
                at any time, in its sole discretion and without advance notice
                to you, cease operation of the platform and distribution of the
                Services
              </p>
              <p>
                <strong>Entire Agreement- </strong>This Agreement comprises the
                entire agreement between you and Rx One Care Pvt Ltd and
                supersedes any prior agreements pertaining to the subject matter
                contained herein.
              </p>
              <p>
                <strong>Use of Data- </strong> Rx One Care Pvt Ltd has a right
                to use the information related to users of the platform for
                research and development, scientific, sales, marketing analysis
                and study purposes. Rx One Care Pvt Ltd has right to use the
                data for commercial purposes however at the time of using the
                data for said purpose, Rx One Care Pvt Ltd shall not disclose
                the personal information of users of Platform.
              </p>
              <p>
                <strong>Effect of Waiver- </strong>The failure of Rx One Care
                Pvt Ltd to exercise or enforce any right or provision of this
                Agreement will not constitute a waiver of such right or
                provision. If any provision of this Agreement is found by a
                court of competent jurisdiction to be invalid, the parties
                nevertheless agree that the court should endeavor to give effect
                to the parties' intentions as reflected in the provision, and
                the other provisions of this Agreement remain in full force and
                effect.
              </p>
              <p>
                <strong>Governing Law; Jurisdiction- </strong>This Agreement
                will be governed by the laws of the State of Delhi, India
                without regard to its conflict of law principles to the
                contrary. Neither you nor Rx One Care Pvt Ltd will commence or
                prosecute any suit, proceeding or claim to enforce the
                provisions of this Agreement, to recover damages for breach of
                or default of this Agreement, or otherwise arising under or by
                reason of this Agreement, other than in courts located in State
                of Delhi, India. By using this platform or ordering Services,
                you consent to the jurisdiction and venue of such courts in
                connection with any action, suit, proceeding or claim arising
                under or by reason of this Agreement. You hereby waive any right
                to trial by any other court arising out of this Agreement and
                any related documents.
              </p>
              <p>
                <strong>Statute of Limitation- </strong>You agree that
                regardless of any statute or law to the contrary, any claim or
                cause of action arising out of or related to use of the platform
                or Services or this Agreement must be filed within one (1) month
                after such claim or cause of action arose or be forever barred.
              </p>
              <p>
                <strong>Waiver of Class Action Rights- </strong> BY ENTERING
                INTO THIS AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU
                MAY HAVE TO JOIN CLAIMS WITH THOSE OF OTHER IN THE FORM OF A
                CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS ARISING
                OUT OF, RELATING TO, OR CONNECTION WITH THIS AGREEMENT MUST BE
                ASSERTED INDIVIDUALLY.
              </p>
              <p>
                <strong>Termination. - </strong> Rx One Care Pvt Ltd reserves
                the right to terminate your access to the platform if it
                reasonably believes, in its sole discretion, that you have
                breached any of the terms and conditions of this Agreement.
                Following termination, you will not be permitted to use the
                platform and Rx One Care Pvt Ltd may, in its sole discretion and
                without advance notice to you, cancel any outstanding orders for
                Services
              </p>
              <p>
                <strong>Domestic Use- </strong> Rx One Care Pvt Ltd makes no
                representation that the platform or Services are appropriate or
                available for use in locations outside India. Users who access
                the Website from outside India do so at their own risk and
                initiative and must bear all responsibility for compliance with
                any applicable local laws.
              </p>
              <p>
                <strong>Assignment- </strong>You may not assign your rights and
                obligations under this Agreement to anyone. Rx One Care Pvt Ltd
                may assign its rights and obligations under this Agreement in
                its sole discretion and without advance notice to you.
              </p>
              <br />
            </li>
            <div className="tt__last">
              <h1>CONTACT US</h1>
              <p>
                Please contact us for any questions or comments (including all
                inquiries unrelated to copyright infringement) regarding this
                Platform.
              </p>
              <br />
              <p>
                In accordance with Information Technology Act 2000 and rules
                made there under, You can write Your grievances to:
              </p>
              <br />
              <p>
                <strong>Address:</strong>Address: HARTRON, IAMAI Plot No1, Udyog
                Vihar, Phase I, Dundahera Village, Sector 20, Gurugram, Haryana
                122022
              </p>
              <br />
              <p>
                <strong>Contact No: </strong> +91 7703934446
              </p>
              <p>
                <strong>Email: </strong>support@rxone.app
              </p>
              <p>
                <strong>Website: </strong> https://www.rxone.app{" "}
              </p>
            </div>
          </ol>
        </div>
      </div>
    </>
  );
}

export default Terms;
