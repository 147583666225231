import React from 'react'

function Receipt({AllDoctors,Appointment,Date,reff}) {


  return (
    <div ref={reff}>
    {/* <ComponentToPrint ref={componentRef} /> */}
    <p className='text-center'>Payment received, your appointment is successfully booked. Please attend Doctor’s appointment On-Time.</p>
    <p className='fw-bold'>Order Summary</p>
    <p style={{fontSize:"13px"}}><span className='fw-bold'>Order ID</span>:   {Appointment.order_id}</p>
    <p style={{fontSize:"13px"}}><span className='fw-bold'>Order On</span>:   {Date}</p>
  <div className='mt-4 mb-0 d-flex justify-content-between'><p className='fs-6 mb-0'>Consultaion with {AllDoctors.doctor_name}</p><p>{Appointment.amount/100}</p></div>
    <hr />
    <p className='fw-bold mb-2'>Payment Method</p>
    <p>online</p>
    </div>
  )
}

export default Receipt