import React from "react";
import Carousel from "nuka-carousel/lib/carousel";
import "../Css/providers.css";
import Media from "react-media";

const NukaCarousel = ({
  nextDisabled = true,
  children,
  previousDisabled = true,
  leftControls = false,
  rightControls = false,
  carouselClass = "",
  slidesToShow = 3,
  slidesToshowOnMobile = 2,
  props,
}) => {
  const carousel = (slidesToShow) => {
    return (
      <Carousel
        nextDisabled={nextDisabled}
        previousDisabled={previousDisabled}
        renderCenterLeftControls={leftControls}
        renderCenterRightControls={rightControls}
        className={carouselClass}
        slidesToShow={slidesToShow}
        {...props}
      >
        {children}
      </Carousel>
    );
  };
  return (
    <>
      <Media query="(max-width: 767px)">
        {(matches) =>
          matches ? carousel(slidesToshowOnMobile) : carousel(slidesToShow)
        }
      </Media>
      {/* <div className="nukacarousel-desktop">{carousel(slidesToShow)}</div>
      <div className="nukacarousel-mob">{carousel(slidesToshowOnMobile)}</div> */}
    </>
  );
};

export default NukaCarousel;
